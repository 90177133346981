import React, { Component, createRef } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import "react-datetime/css/react-datetime.css";
import { getMachineSerialNoLookups } from "../../../models/_machineSerialNumber";
import { KTSVG } from "../../../../_metronic/helpers";
import Datetime from "react-datetime";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Moment from "moment";

export default class MachineSerialNumberList extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      tableRef: createRef(),
      resetFrom: createRef(),
      resetTo: createRef(),
      resetMechanicalHardwareVersion: createRef(),
      filterLoading: false,

      addLoading: false,
      filterToDate: "",
      filterFromDate: "",
      filterSerialNumber: "",
      filterMechanicalHardwareVersion: "",
      currentlyOpenRecord: {},
      serialNumber: "",
      mechanicalHardwareVersion: "",
      machineDB: [],
    };
  }
  async componentWillMount() {}
  async componentDidUpdate() {}

  async componentDidMount() {
    
    $("body").on("change", ".togBtn", function () {
      return false;
    });

    const rows = await this.getData();
    this.renderDataTable(rows);
  }

  // rendering datatable jquery
  renderDataTable = (rows: any) => {
    $(document).ready(function () {
      $("#machineSerialNumberTable").DataTable().destroy();
      $("#machineSerialNumberTable").DataTable({
        data: rows,
        columns: [
          {
            name: "index",
            render: function (data, type, row) {
              return row.id;
            },
          },
          {
            name: "serialNumber",
            render: function (data, type, row) {
              return row.serialNumber;
            },
          },

          {
            name: "mechanicalHardwareVersion",
            render: function (data, type, row) {
              return row.mechanicalHardwareVersion;
            },
          },
          {
            name: "createdAt",
            render: function (data, type, row) {
              return row.createdAt;
            },
          },
        ],

        pagingType: "full_numbers",
        pageLength: 20,
        processing: true,
        dom: "Bfrtip",
        destroy: true,
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
      });

      $(".dt-buttons").remove();
    });
  };

  // fetch data into datatable
  getFilterRecords = async () => {};

  getData = async () => {
    let filter: any = {};
    if (this.state.filterSerialNumber) {
      filter["serial_number"] = {
        like: "%" + this.state.filterSerialNumber + "%",
      };
    }
    if (this.state.filterMechanicalHardwareVersion) {
      filter["mechanical_hardware_version"] = {
        eq: this.state.filterMechanicalHardwareVersion,
      };
    }
    if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
      filter["created_at"] = {};
    }
    if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
      filter["created_at"]["gt"] = new Date(
        this.state.filterFromDate
      ).toISOString();
    } else if (
      this.state.filterFromDate === "" &&
      this.state.filterToDate !== ""
    ) {
      filter["created_at"]["lt"] = new Date(
        this.state.filterToDate
      ).toISOString();
    } else if (
      this.state.filterFromDate !== "" &&
      this.state.filterToDate !== ""
    ) {
      filter["created_at"]["between"] = [
        new Date(this.state.filterFromDate).toISOString(),
        new Date(this.state.filterToDate).toISOString(),
      ];
    }

    const records = await getMachineSerialNoLookups(filter);

    const rows = [];

    let counter = 0;
    for (const record of records.data) {
      counter++;
      let createdAtDate = "";
      if (record.createdAt) {
        createdAtDate = Moment.utc(record.createdAt)
          .local()
          .format("YYYY-MM-DD HH:mm:ss");
      }
      const tempRecord = {
        id: counter,
        recordId: record.id,
        serialNumber: record.serialNumber,
        mechanicalHardwareVersion: record.mechanicalHardwareVersion,
        createdAt: createdAtDate,
        record: record,
      };
      rows.push(tempRecord);
    }
    const machinesRecords = await getMachineSerialNoLookups();
    const machinesResults: any = machinesRecords.data
      ? machinesRecords.data
      : [];

    this.setState({
      machineDB: machinesResults,
    });

    return rows;
  };

  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    const rows = await this.getData();
    this.renderDataTable(rows);
    this.setState({
      addLoading: false,
    });
    return false;
  };
  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });

    // eslint-disable-next-line
    this.state.resetFrom.current.state.inputValue = "";
    // eslint-disable-next-line
    this.state.resetTo.current.state.inputValue = "";

    this.setState({
      filterLoading: false,
      filterToDate: "",
      filterFromDate: "",
      filterSerialNumber: "",
      filterMechanicalHardwareVersion: "",
    });
    await this.getFilterRecords();
    const rows = await this.getData();
    this.renderDataTable(rows);

    return false;
  };
  render() {
    return (
      <div>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Machine Serial Number List
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3mt-3 mt-lg-0 mt-md-0">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">
                  Machine Serial Number List
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <button
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#blender-filter"
                  aria-expanded="false"
                  aria-controls="blender-filter"
                  className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center"
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen031.svg"
                    className="svg-icon-4 me-1"
                  />
                  Filter
                </button>
              </div>
            </div>
            <div
              className="collapse"
              id="blender-filter"
              aria-labelledby="blender-filter"
            >
              <div className="row mt-6">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Created At (From Date)
                  </label>
                  <Datetime
                    ref={this.state.resetFrom}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    initialValue={this.state.filterFromDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterFromDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Created At (To Date)
                  </label>
                  <Datetime
                    ref={this.state.resetTo}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    initialValue={this.state.filterToDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterToDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Serial Number
                  </label>

                  <ReactSearchAutocomplete
                    items={this.state.machineDB.map((machine: any) => ({
                      name: machine.serialNumber,
                      id: machine.serialNumber,
                    }))}
                    onSelect={(item: any) => {
                      this.setState({
                        filterSerialNumber: item.name,
                      });
                    }}
                    onSearch={(keyword) => {
                      this.setState({
                        filterSerialNumber: keyword,
                      });
                    }}
                    autoFocus
                    styling={{
                      borderRadius: "5px",
                      zIndex: 1,
                    }}
                    fuseOptions={{
                      threshold: 0.2,
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">
                    Mechanical Hardware Version
                  </label>
                  <input
                    autoComplete="off"
                    className="form-control"
                    name="filterMechanicalHardwareVersion"
                    value={this.state.filterMechanicalHardwareVersion}
                    onChange={(event: any) => {
                      this.setState({
                        filterMechanicalHardwareVersion: event.target.value,
                      });
                    }}
                  />
                  {/* <Select
                    ref={this.state.resetMechanicalHardwareVersion}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="filterMechanicalHardwareVersion"
                    placeholder=""
                    onChange={(event: any) => {
                      this.setState({
                        filterMechanicalHardwareVersion: event.value,
                      });
                    }}
                    options={this.state.machineDB.map((value: any) => {
                      return {
                        value: value.mechanicalHardwareVersion,
                        label: value.mechanicalHardwareVersion,
                      };
                    })}
                  /> */}
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.searchData}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.addLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.addLoading && (
                      <span className="indicator-label">ADD FILTER</span>
                    )}
                  </button>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.removeSearch}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.filterLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.filterLoading && (
                      <span className="indicator-label">RESET FILTER</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="machineSerialNumberTable"
                    className="table align-items-center display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Sr No.
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Serial Number
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Mechanical Hardware Version
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created At
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
