import React, { FC, Suspense, useState, useEffect } from 'react'
import { getMachineListById } from './ViewMachine/MachineHistory'
import { useNavigate } from 'react-router-dom';
import { useLazyLoadQuery } from 'react-relay';
import Modal from 'react-responsive-modal';
import toast, { Toaster } from "react-hot-toast";
import { ErrorMessage, Formik } from "formik";
import { MachineHistory_getmachineQuery } from './ViewMachine/__generated__/MachineHistory_getmachineQuery.graphql'
import { getLocations, getUsers } from "../../models/_location";
import { changeMachineLocation, getMachineStatusList } from "../../models/_machine";
import * as yup from "yup";
import Select from "react-select";
import clsx from 'clsx';
import * as _ from 'lodash';
import Datetime from "react-datetime";

type Props = {
  currentMachineId: string
  assignLocationModalOpen: boolean
  handleCloseAssignLocation: () => void;
  refreshTable?: any
}

const assignLocationSchema = yup.object({
  locationId: yup.string().required("Location is required"),
  machineStatusId: yup.string().required("Machine status is required"),
})
const AssignLocationModal: FC<Props> = ({
  assignLocationModalOpen,
  handleCloseAssignLocation,
  currentMachineId,
  refreshTable
}) => {
  const navigate = useNavigate()
  if (!currentMachineId) {
    navigate("/machines/list");
  }
  const machineRecord = useLazyLoadQuery<MachineHistory_getmachineQuery>(
    getMachineListById,
    {
      id: currentMachineId
    }, {
    fetchPolicy: "network-only",
  },)
  const machines: any = machineRecord?.getmachine ?? [];
  if (machines == null) {
    navigate("/machines/list");
  }

  const [loading, setLoading] = useState(false);
  const [locationDB, setLocationDB] = useState([]);
  const [machineStatusDB, setMachineStatusDB] = useState([]);
  const [managerDB, setManagerDB] = useState([]);
  const [assignLocationEdit] = useState({
    id: machines?.id,
    locationId: "",
    isPilot: false,
    trialEndDate: "",
    machineStatusId: "",
    deployingPersonId: "",
  })
  const fetchData = async () => {
    try {
      const locationIdRecord = await getLocations();
      const locationIdResults: any = locationIdRecord?.data ? locationIdRecord?.data : [];
      locationIdResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      const machineStatusRecord = await getMachineStatusList();
      const machineStatusResults: any = machineStatusRecord
        ? machineStatusRecord
        : [];
      machineStatusResults.sort(function (a: any, b: any) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      const deploymentManagerRecord = await getUsers(true);
      const deploymentManagerResults: any = deploymentManagerRecord
        ? deploymentManagerRecord
        : [];
      deploymentManagerResults.sort(function (a: any, b: any) {
        if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
          return -1;
        }
        if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      setLocationDB(locationIdResults);
      setMachineStatusDB(machineStatusResults);
      setManagerDB(deploymentManagerResults);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Suspense>
        <Modal
          open={assignLocationModalOpen}
          onClose={() => handleCloseAssignLocation()}
          role="modal"
          center
          classNames={{
            root: "content-wrapper",
          }}
        >
          <Formik
            enableReinitialize
            initialValues={assignLocationEdit}
            validationSchema={assignLocationSchema}
            onSubmit={async (values, { resetForm, setSubmitting }) => {
              setLoading(true);
              const updateData = {
                id: values.id,
                locationId: values.locationId,
                machineStatusId: values.machineStatusId,
                isPilot: values.isPilot,
                trialEndDate: values.trialEndDate,
                deployingPersonId: values.deployingPersonId,
              };
              const result = await changeMachineLocation(
                updateData.id,
                updateData.locationId,
                values.machineStatusId,
                values.isPilot,
                values.trialEndDate,
                values.deployingPersonId
              );

              if (result?.data?.data?.changeMachineLocation) {
                toast.success("Location assigned successfully");
                setLoading(false)
                handleCloseAssignLocation()
                resetForm()
                if (typeof refreshTable !== 'undefined') {
                  refreshTable()
                }
              } else {
                setLoading(false)
                const messages = result?.data?.errors.map((e: any) => e.message);
                toast.error(messages.join(","));
              }
            }}

          >
            {({
              handleChange,
              handleSubmit,
              values,
              errors,
              touched,
              setFieldValue,
            }) =>
              <form
                id="kt_modal_add_lesson_form"
                className="form"
                onSubmit={handleSubmit} autoComplete="off"
                noValidate>
                <div className="modal-header p-2 pb-4 mb-4">
                  <h4 className="modal-title fs-1 pe-9">
                    Location - {machines.uuid}
                  </h4>
                </div>
                <div className="modal-body">
                  <div className="col-12 mt-6 pt-2">
                    <label className="form-label fs-4 required fw-bold">
                      Assign Location
                    </label>
                    <Select
                      className="react-select "
                      classNamePrefix="my-react-select"
                      name="locationId"
                      placeholder=""
                      onChange={(event: any) => {
                        setFieldValue('locationId', event.value)
                      }}
                      options={Array.from(locationDB).map(
                        (value: any) => {
                          return {
                            value: value.id,
                            label: value.name,
                          };
                        }
                      )}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="locationId" />
                    </div>
                  </div>

                  {values.locationId !== '' ? <div className="col-12 col-md-12 col-12 mt-3 pt-2">
                    <label className="form-label fs-4 fw-bold d-block">
                      Is Pilot?
                    </label>
                    <div className="form-check form-switch form-check-custom form-check-solid w-100">
                      <input
                        className="form-check-input"
                        type="checkbox"

                        defaultChecked={values.isPilot === true}
                        onChange={(event) => {
                          setFieldValue("isPilot", event.target.checked)
                          setFieldValue("trialEndDate", "")
                        }
                        }
                        name="isPilot"
                      />
                    </div>
                  </div> : ''}

                  {values.isPilot ?
                    <div className="col-12 col-md-12 col-12 mt-3 pt-2">
                      <label className="form-label fs-4 required fw-bold">
                        Trial End Date
                      </label>
                      <Datetime
                        dateFormat="YYYY-MM-DD"
                        closeOnSelect
                        timeFormat={false}
                        className={clsx("date-control", {
                          "is-invalid":
                            Boolean(errors.trialEndDate) &&
                            touched.trialEndDate,
                        })}
                        value={values.trialEndDate}
                        onChange={(e: any) => {
                          const dt = e["_d"];
                          setFieldValue("trialEndDate", dt);
                        }}
                      />
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="trialEndDate" />
                      </div>
                    </div> : ''}

                    <div className="col-12 col-md-12 col-12 mt-3 pt-2">
                    <label className="form-label required fs-4 fw-bold">
                      Machine Status
                    </label>
                    <Select
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="machineStatusId"

                      options={Array.from(machineStatusDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: _.capitalize(_.lowerCase(value.name)),
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("machineStatusId", e.value)
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="machineStatusId" />
                    </div>
                  </div>

                  <div className="col-12 col-md-12 col-12 mt-3 pt-2">
                    <label className="form-label required fs-4 fw-bold">
                      Person Deploying
                    </label>
                    <Select
                      className="react-select"
                      classNamePrefix="my-react-select"
                      name="deployingPersonId"

                      options={Array.from(managerDB).map((value: any) => {
                        return {
                          value: value.id,
                          label: value.firstName,
                        };
                      })}
                      onChange={(e: any) => {
                        setFieldValue("deployingPersonId", e.value)
                      }}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="deployingPersonId" />
                    </div>
                  </div>

                </div>
                <div className="row">
                  <div className="col-12 col-md-12 col-12 mt-3 pt-2">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 w-100"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">SUBMIT</span>
                      )}
                    </button>
                  </div>
                </div>


              </form>}
          </Formik>
        </Modal>
        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </Suspense>
    </div>
  )
}

export default AssignLocationModal;
