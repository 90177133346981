import Modal from "react-responsive-modal";
import { FC, Suspense } from "react";


type Props = {
    currentlyOpenRecord: any;
    mediaImageModal: boolean;
    handleMediaImageClose: () => void;
};
export const MediaImageModal: FC<Props> = ({
    currentlyOpenRecord,
    mediaImageModal,
    handleMediaImageClose,
}) => {
    return (
        <Suspense>
            <Modal
                open={mediaImageModal}
                onClose={() => handleMediaImageClose()}
                role="modal"
                center
                classNames={{ root: "content-wrapper" }}
            >
                <div>
                    <img
                        src={currentlyOpenRecord}
                        alt={`media`}
                        className="w-100 h-100 rounded-lg"
                    />
                </div>
            </Modal>
        </Suspense>
    );
};