import React, { FC, Suspense, useState } from 'react';
import Modal from 'react-responsive-modal';
import { useNavigate } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

type Props = {
  currentWorkOrderId: string;
  imageContent: any; 
  handleWorkOrderImageClose: () => void;
  workOrderImageModal: boolean;
  refreshTable?: () => void;
};

export const ImageModal: FC<Props> = ({
  currentWorkOrderId,
  imageContent,
  workOrderImageModal,
  handleWorkOrderImageClose,
  refreshTable,
}) => {
  const navigate = useNavigate();
  if (!currentWorkOrderId || !imageContent) {
    navigate("/workOder");
  }
  const [showPreview, setShowPreview] = useState(false);
  const [previewContent, setPreviewContent] = useState('');
  const [isVideo, setIsVideo] = useState(false);
  
  return (
    <Suspense>
      <Modal
        open={workOrderImageModal}
        onClose={handleWorkOrderImageClose}
        role="modal"
        center
        classNames={{
          root: "content-wrapper",
        }}
      >
        <div>
          <div className="modal-header p-2 pb-4 mb-4">
            <h4 className="modal-title fs-1">View images</h4>
          </div>

          <div className="modal-body">
            <div className="row">
              <div className="col-12 mt-2 pt-2 w-100">
                <label className='form-label fs-4 fw-bold'>Close Up Media</label>
                <div className="row">
                  {imageContent.closeUpMedia.map((a: string, index: number) => {
                    const isVideo = a.endsWith('.mp4') || a.endsWith('.webm') || a.endsWith('.ogg');
                    return (
                      <div className="col-3 mb-3" key={index}>
                        <div
                          className="media-box"
                          onClick={() => {
                            setPreviewContent(a);
                            setIsVideo(isVideo);
                            setShowPreview(true);
                          }}
                        >
                          {isVideo ? (
                            <video
                              className="media-content"
                            >
                              <source src={a} type="video/mp4" />
                            </video>
                          ) : (
                            <img
                              src={a}
                              alt={`Close Up ${index + 1}`}
                              className="media-content"
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-2 pt-2 w-100">
                <label className='form-label fs-4 fw-bold'>Store Set Up Media</label>
                <div className="row">
                  {imageContent.storeSetUpMedia.map((a: string, index: number) => {
                    const isVideo = a.endsWith('.mp4') || a.endsWith('.webm') || a.endsWith('.ogg');
                    return (
                      <div className="col-3 mb-3" key={index}>
                        <div
                          className="media-box"
                          onClick={() => {
                            setPreviewContent(a);
                            setIsVideo(isVideo);
                            setShowPreview(true);
                          }}
                        >
                          {isVideo ? (
                            <video
                              className="media-content"
                            >
                              <source src={a} type="video/mp4" />
                            </video>
                          ) : (
                            <img
                              src={a}
                              alt={`Close Up ${index + 1}`}
                              className="media-content"
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-2 pt-2 w-100">
                <label className='form-label fs-4 fw-bold'>Drain Set Up Media</label>
                <div className="row">
                  {imageContent.drainSetUpMedia.map((a: string, index: number) => {
                    const isVideo = a.endsWith('.mp4') || a.endsWith('.webm') || a.endsWith('.ogg');
                    return (
                      <div className="col-3 mb-3" key={index}>
                        <div
                          className="media-box"
                          onClick={() => {
                            setPreviewContent(a);
                            setIsVideo(isVideo);
                            setShowPreview(true);
                          }}
                        >
                          {isVideo ? (
                            <video
                              className="media-content"
                            >
                              <source src={a} type="video/mp4" />
                            </video>
                          ) : (
                            <img
                              src={a}
                              alt={`Close Up ${index + 1}`}
                              className="media-content"
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-2 pt-2 w-100">
                <label className='form-label fs-4 fw-bold'>Serial Number Media</label>
                <div className="row">
                  {imageContent.serialNumberMedia.map((a: string, index: number) => {
                    const isVideo = a.endsWith('.mp4') || a.endsWith('.webm') || a.endsWith('.ogg');
                    return (
                      <div className="col-3 mb-3" key={index}>
                        <div
                          className="media-box"
                          onClick={() => {
                            setPreviewContent(a);
                            setIsVideo(isVideo);
                            setShowPreview(true);
                          }}
                        >
                          {isVideo ? (
                            <video
                              className="media-content"
                            >
                              <source src={a} type="video/mp4" />
                            </video>
                          ) : (
                            <img
                              src={a}
                              alt={`Close Up ${index + 1}`}
                              className="media-content"
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mt-2 pt-2 w-100">
                <label className='form-label fs-4 fw-bold'>Freezer Door Open Media</label>
                <div className="row">
                  {imageContent.freezerDoorOpenMedia.map((a: string, index: number) => {
                    const isVideo = a.endsWith('.mp4') || a.endsWith('.webm') || a.endsWith('.ogg');
                    return (
                      <div className="col-3 mb-3" key={index}>
                        <div
                          className="media-box"
                          onClick={() => {
                            setPreviewContent(a);
                            setIsVideo(isVideo);
                            setShowPreview(true);
                          }}
                        >
                          {isVideo ? (
                            <video
                              className="media-content"
                            >
                              <source src={a} type="video/mp4" />
                            </video>
                          ) : (
                            <img
                              src={a}
                              alt={`Freezer Door Open ${index + 1}`}
                              className="media-content"
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {showPreview && (
            <div className="preview-modal" onClick={() => setShowPreview(false)}>
              <div className="preview-content" onClick={(e) => e.stopPropagation()}>
                <button className="preview-close-btn" onClick={() => {
                  setShowPreview(false);
                  setIsVideo(false);
                }}>
                  &times;
                </button>
                {isVideo ? (
                  <video controls className="preview-media" onEnded={() => setShowPreview(false)}>
                    <source src={previewContent} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <img src={previewContent} alt="Preview" className="preview-media" />
                )}
              </div>
            </div>
          )}

        </div>
      </Modal>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          className: "",
          duration: 3000,
        }}
      />
    </Suspense>
  );
};
