import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateCupHolderInput {
  title: string;
  isActive: boolean;
  description: string;
}

interface UpdateCupHolderInput {
   id: string;
   title?: string;
   description?: string;
   isActive?: boolean;
}

export async function createCupHolder(input: CreateCupHolderInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createCupHolder (
            $input: CreateCupHolderInput!
          ){
            createCupHolder(
               createCupHolderInput: $input
              )  {
                    id
                    title
                    createdAt
                    description
                    updatedAt
                    createdById
                    updatedById
                    isActive
              }
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getCupHolders(
  where: {} = {},
  pagination: {} | undefined = undefined
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query getCupHolders(
                  $where: CupHoldersResolver_GetCupHolders_FilterInputType,
                  $paginate: PaginatorArgs
                  ) {
                    getCupHolders (where:$where, paginate: $paginate) {
                      data {
                          id
                          mediaUrl
                          title
                          createdAt
                          description
                          updatedAt
                          createdById
                          updatedById
                          isActive
                        }
                        totalPages
                        total
                        perPage
                        page
                    
                  }
                }
                `,
      variables: { where, paginate: pagination },
    },
  });
  return data?.data?.data?.getCupHolders;
}

export async function getCupHoldersList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query {
                  getCupHoldersList{
                    id
                    title
                    mediaUrl
                    description
                    createdAt
                    updatedAt
                    createdById
                    updatedById
                    isActive
                   }
                }
                `,
      variables: {},
    },
  });
  return data?.data?.data?.getCupHoldersList;
}

export async function updateCupHolder(input: UpdateCupHolderInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation updateCupHolder (
              $input: UpdateCupHolderInput!
            ){
              updateCupHolder(
                updateCupHolderInput: $input
                )  {
                  id
                  title
                  description
                  createdAt
                  updatedAt
                  createdById
                  updatedById
                  isActive
                }
            }
            `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function updateCupHolderStatus(id: string, isActive: boolean) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation ($id: String!, $isActive: Boolean! ) {
        updateCupHolderStatus(
            updateCupHolderStatusInput: { id: $id, isActive: $isActive }
              )
            }
            `,
      variables: {
        id,
        isActive,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
