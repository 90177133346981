import React, { Component, createRef } from "react";
import { KTSVG } from "../../../_metronic/helpers";
import Select from "react-select";
import { getLocations, getUsers } from "../../models/_location";
import Moment from "moment";
import * as _ from "lodash";
import $, { event } from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { swapDeploymentManager } from "../../models/_replaceManager";

export default class ReplaceManager extends Component<any, any> {
  readonly props: any;
  sigPad: any = {};
  constructor(props: any) {
    super(props);

    this.state = {
      resetNewManager: createRef(),
      resetOldManager: createRef(),
      managerDB: [],
      addLoading: false,
      oldManager: "",
      newManager: "",
    };
  }
  async componentWillMount() {}

  async componentDidUpdate() {}

  async componentDidMount() {
    await this.handleOldManager(event);

    await this.getFilterRecords();
    const rows = await this.getData();
    this.renderDataTable(rows);
  }

  // rendering datatable jquery
  renderDataTable = (rows: any) => {
    $(document).ready(function () {
      $("#replaceTable").DataTable().destroy();

      $("#replaceTable").DataTable({
        pagingType: "full_numbers",
        data: rows,
        columns: [
          {
            name: "index",
            render: function (data, type, row) {
              return row.id;
            },
          },
          {
            name: "customerName",
            render: function (data, type, row) {
              return row.customerName;
            },
          },
          {
            name: "locationName",
            render: function (data, type, row) {
              return row.locationName;
            },
          },
          {
            name: "locationAddress",
            render: function (data, type, row) {
              return row.locationAddress;
            },
          },
          {
            name: "locationRegion",
            render: function (data, type, row) {
              return row.locationRegion;
            },
          },
          {
            name: "marketSegment",
            render: function (data, type, row) {
              return row.marketSegment;
            },
          },
          {
            name: "deploymentManager",
            render: function (data, type, row) {
              let returnsData =
                '<div class="fw-bold">' + row.deploymentManager + "</div>";
              return returnsData;
            },
          },
          {
            name: "distributor",
            render: function (data, type, row) {
              return row.distributor;
            },
          },
          {
            name: "locationType",
            render: function (data, type, row) {
              return row.locationType;
            },
          },
          {
            name: "targetLaunchDate",
            render: function (data, type, row) {
              return row.targetLaunchDate;
            },
          }
        ],
        pageLength: 20,
        processing: true,
        dom: "Bfrtip",
        destroy: true,
        order: [[0, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
        createdRow: function (row, data, rowIndex) {
          var title: any = [];
          $("#replaceTable thead tr th").each(function () {
            title.push($(this).text());
          });
          $.each($("td", row), function (colIndex) {
            $(this).attr("data-title", title[colIndex]);
          });
        },
      });

      $(".dt-buttons").remove();
    });
  };

  getFilterRecords = async () => {
    const deploymentManagerRecord = await getUsers(true);
    const deploymentManagerResults: any = deploymentManagerRecord
      ? deploymentManagerRecord
      : [];
    deploymentManagerResults.sort(function (a: any, b: any) {
      if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) {
        return -1;
      }
      if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) {
        return 1;
      }
      return 0;
    });

    this.setState({
      managerDB: deploymentManagerResults,
    });
  };

  handleReplace = async () => {
    let hasErrors = false;
    if (!this.state.oldManager) {
      hasErrors = true;
      toast.error("Please select old manager!");
    }
    if (!this.state.newManager) {
      hasErrors = true;
      toast.error("Please select new manager!");
    }
    if (hasErrors) {
      return false;
    }
    this.setState({
      addLoading: true,
    });
    const updateData = {
      oldDeploymentManagerId: this.state.oldManager,
      newDeploymentManagerId: this.state.newManager,
    };
    const result = await swapDeploymentManager(updateData);
    if (result?.data?.data?.swapDeploymentManager) {
      toast.success("Manager replaced successfully");
      this.state.resetNewManager.current.setValue("");
      this.state.resetOldManager.current.setValue("");
      const rows = await this.getData();
      this.renderDataTable(rows);
      this.setState({
        addLoading: false,
      });
    } else {
      this.setState({
        addLoading: false,
      });
      const messages = result?.data?.errors.map((e: any) => e.message);
      toast.error(messages.join(","));
    }
  };
  handleOldManager = async (event: any) => {
    this.setState({
      oldManager: event.value,
    });
    await this.getFilterRecords();
    const rows = await this.getData();
    this.renderDataTable(rows);
  };

  // fetching data into location table
  getData = async () => {
    $("#replaceTable").addClass('table-spinner');
    let filter: any = {};
    if (this.state.oldManager) {
      filter["deployment_manager_id"] = { eq: this.state.oldManager };
    } else {
      filter["deployment_manager_id"] = { eq: null };
    }
    const records: any = await getLocations(filter);

    const rows = [];
    for (const record of records.data) {
      const tempRecord = {
        id: record.uuid,
        customerName: record.customer?.name ?? "",
        locationName: record.name,
        locationAddress: record.address,
        locationRegion: record.region?.name ?? "",
        marketSegment: record.marketSegment?.name ?? "",
        deploymentManager: record.deploymentManager?.firstName ?? "",
        locationType: _.capitalize(
          _.lowerCase(record.locationType?.name ?? "")
        ),
        distributor: record.distributor?.name ?? "",
        targetLaunchDate: Moment.utc(record.targetLaunchDate)
          .local()
          .format("YYYY-MM-DD"),
        contactName: record.contactName,
        contactPhone: record.contactPhone,
        recordId: record.id,
      };
      rows.push(tempRecord);
    }
    $("#replaceTable").removeClass('table-spinner');
    return rows;
  };

  render() {
    return (
      <div>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Replace Regional Manager
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">
                  Replace Regional Manager
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <h3 className="btn btn-bg-light fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center">
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr029.svg"
                    className="svg-icon-4 me-1"
                  />
                  Replace
                </h3>
              </div>
            </div>
            <div className="row mt-6">
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                <label className="form-label fs-4 fw-bold">Current Regional Manager</label>
                <Select
                  ref={this.state.resetOldManager}
                  className="react-select"
                  classNamePrefix="my-react-select"
                  isOptionDisabled={(option) =>
                    option.value === this.state.newManager
                  }
                  name="oldManager"
                  placeholder=""
                  onChange={this.handleOldManager}
                  options={Array.from(this.state.managerDB).map(
                    (value: any) => {
                      return {
                        value: value.id,
                        label: value.firstName,
                      };
                    }
                  )}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                <label className="form-label fs-4 fw-bold">New Regional Manager</label>
                <Select
                  ref={this.state.resetNewManager}
                  className="react-select"
                  classNamePrefix="my-react-select"
                  name="newManager"
                  isOptionDisabled={(option) =>
                    option.value === this.state.oldManager
                  }
                  placeholder=""
                  onChange={(event: any) => {
                    this.setState({
                      newManager: event.value,
                    });
                  }}
                  options={Array.from(this.state.managerDB).map(
                    (value: any) => {
                      return {
                        value: value.id,
                        label: value.firstName,
                      };
                    }
                  )}
                />
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mt-lg-9 pt-lg-2">
                <button
                  type="submit"
                  onClick={this.handleReplace}
                  className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                >
                  {this.state.addLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!this.state.addLoading && (
                    <span className="indicator-label">REPLACE</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <h3 className="btn btn-bg-light fw-bold m-0 mb-3 p-0 px-5 py-3 align-items-center card-title text-center">
                  <KTSVG
                    path="/media/icons/duotune/maps/map008.svg"
                    className="svg-icon-4 me-1"
                  />
                  Locations
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div
                  className="table-responsive display p-0 pb-2 fs-6"
                  id="data-table"
                >
                  <table
                    id="replaceTable"
                    className="table align-items-center responsive-table display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location ID
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Customer Name
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location Name
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location Address
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location Region
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Market Segment
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Deployment Manager
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Distributor
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Location Type
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Target Date
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    );
  }
}
