import React, { useState } from "react";
import { ErrorMessage, Formik } from "formik";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import clsx from "clsx";
import { createCupHolder } from "../../../models/_cupHolders";
import { handleMediaFileUpload } from "../TubeShield/CreateTubeShield";
import { FOLDER_NAME } from "../../../../_metronic/helpers/enums";

const validationSchema = yup.object({
  title: yup.string().required("Title is required"),
  isActive: yup.string().required("Status is required"),
  mediaFile: yup.mixed()
  .nullable()
  .test("fileType", "Only image files are supported", (value) =>
    value ? ["image/jpeg", "image/png", "image/gif"].includes(value.type) : true
  ),
});
const isActiveDB = [
  {
    id: true,
    name: "Active",
  },
  {
    id: false,
    name: "Inactive",
  },
];
function CreateCupHolder() {
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
        <div
          id="kt_app_toolbar_container"
          className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
          style={{ maxWidth: "100%" }}
        >
          <div className="page-title d-flex flex-column justify-content-center flex-wrap">
            <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Create Cup Holder
            </h1>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <a href="/" className="text-muted text-hover-primary fs-5">
                  Home
                </a>
              </li>
              <li className="breadcrumb-item">
                <span className="bullet bg-gray-400 w-5px h-2px" />
              </li>
              <li className="breadcrumb-item text-dark fs-5">Create Cup Holder</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="card mx-xl-4 mt-9">
        <Formik
          initialValues={{
            title: "",
            description: "",
            isActive: "",
            mediaFile: null,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm, setSubmitting }) => {
            setLoading(true);
            let $inputData: any = {
              title: values.title,
              description: values.description,
              isActive: values.isActive,
            };
            if(values.mediaFile) {
              const url = await handleMediaFileUpload(values.mediaFile,FOLDER_NAME.CUP_HOLDER_IMAGES);
              $inputData.mediaUrl = url;
             }
            const result = await createCupHolder($inputData);

            if (result?.data?.data?.createCupHolder) {
              toast.success("Cup holder created successfully");
              setSubmitting(false);
              setLoading(false);
              resetForm();
            } else {
              setSubmitting(false);
              setLoading(false);
              const messages = result?.data?.errors.map((e: any) => e.message);
              toast.error(messages.join(","));
            }
          }}
        >
          {({
            handleChange,
            handleSubmit,
            values,
            errors,
            touched,
            setFieldValue,
          }) => (
            <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <form onSubmit={handleSubmit} autoComplete="off">
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 required fw-bold">
                      Title
                    </label>
                    <input
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.title) && touched.title,
                      })}
                      name="title"
                      id="title"
                      value={values.title}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="title" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                    <label className="form-label fs-4 fw-bold">
                      Description
                    </label>
                    <textarea
                      name="description"
                      id="description"
                      className={clsx("form-control", {
                        "is-invalid": Boolean(errors.description) && touched.description,
                      })}
                      value={values.description}
                      onChange={handleChange}
                    />
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="description" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                    <label className="form-label fw-bold required fs-4">
                      Status
                    </label>
                    {Array.from(isActiveDB).map((value: any, id) => (
                      <div className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-2 ms-2">
                        <label className="ms-2 fs-5" key={id}>
                          <input
                            id="isActive"
                            className="form-check-input"
                            type="radio"
                            name="isActive"
                            value={value.id}
                            checked={values.isActive === value.id}
                            onChange={() => setFieldValue("isActive", value.id)}
                          />
                          <span className="form-check-label fs-5 mb-0 ms-2">
                            {value.name}
                          </span>
                        </label>
                      </div>
                    ))}
                    <div className="text-danger text-align-top-center mt-2">
                      <ErrorMessage name="isActive" />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                     <label className="form-label fs-4 fw-bold">
                      Media File
                    </label>
                    <div className="d-flex">
                      <label htmlFor="mediaFile" className="fs-4">
                        <input
                          type="file"
                          id="mediaFile"
                          accept="image/*"
                          placeholder="Upload media file"
                          className="d-none"
                          onChange={(event) => {
                            const file = event.currentTarget.files ? event.currentTarget.files[0] : null;
                            setFieldValue("mediaFile", file);
                          }}
                        />
                        <span
                          className="btn btn-bg-light text-gray-700 fs-4 w-100 mt-2 ms-2 fs-5 px-5 py-3 w-100"
                          style={{ cursor: "pointer" }}
                        >
                          Upload Media File
                        </span>
                      </label>
                    </div>
                      <div className="text-danger text-align-top-center mt-2">
                        <ErrorMessage name="mediaFile" />
                      </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-12 px-xl-5 p-2">
                    <button
                      type="submit"
                      className="btn btn-primary me-4 fs-4 w-100"
                    >
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please Wait..
                          <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                        </span>
                      )}
                      {!loading && (
                        <span className="indicator-label">ADD CUP HOLDER</span>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </Formik>

        <Toaster
          position="bottom-left"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: "",
            duration: 3000,
          }}
        />
      </div>
    </div>
  );
}

export default CreateCupHolder;