import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

interface CreateTicketInput {
  ticketType?: string;
  machineId?: string;
  ticketSourceId?: string;
  reportedAt?: string;
  assignToId: string;
  priority: string;
  serviceType?: string;
  comment?: string;
  chatDescription?: string;
  machineServices: string[];
  marketingMaterials: string[];
  marketingOrOthers: string[];
  operations: string[];
  howTicketClosed?: string | null;
  closingDateAndTime?: string;
  isClosed: boolean;
  timeSpentOnTask?: number;
  travelTime?: number;
  customerSatisfaction?: string | null;
  cleanliness?: string | null;
  trafficRate?: string | null;
  staffEnthusiasm?: string | null;

  media?: string[];
  machineServiceOtherInput: string;
  operationOtherInput: string;
  marketingOtherInput: string;
  issueReportedOtherInput: string;
  issuesReported: string[];
}
export async function createTicket(input: CreateTicketInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
        mutation createTicket (
          $input: CreateTicketInput!
        ){
          createTicket(
            createTicketInput: $input
            ) {
                id
                uuid
                ticketType
                machineId
                parentId
                ticketSourceId
                reportedAt
                assignToId
                priority
                comment
                status
                howTicketClosed
                closingDateAndTime
                timeSpentOnTask
                travelTime
                customerSatisfaction
                cleanliness
                trafficRate
                staffEnthusiasm
                createdById
                createdAt
                updatedById
                reportedByType
                reportedById
                reportedByName
                reportedByPhone
                reportedByEmail
                reportedByUser {
                  firstName
                  lastName
                }
                media{
                  url
                  type
                }
                updatedAt
                machine {
                  id
                  serialNumber
                  name
                  deploymentDate
                  locationId
                  deployingPersonId
                  softwareVersion
                  hardwareVersion
                  createdById
                  updatedById
                  isActive
                }
                ticketSource {
                  id
                  name
                }
                assignTo {
                  id
                  firstName
                }
                createdBy {
                  id
                  firstName
                }
                subTasks {
                  id
                  ticketType
                  machineId
                  parentId
                  ticketSourceId
                  issueReportedOtherInput
                  machineServiceOtherInput
                  operationOtherInput
                  marketingOtherInput
                  reportedAt
                  assignToId
                  priority
                  serviceType
                  comment
                  status
                  howTicketClosed
                  closingDateAndTime
                  timeSpentOnTask
                  travelTime
                  customerSatisfaction
                  cleanliness
                  trafficRate
                  staffEnthusiasm
                  createdById
                  createdAt
                  updatedById
                  updatedAt
                }
            }
        }
        `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));

  return data;
}

export async function getTickets(
  where: {} = {},
  paginate: any = undefined,
  customFilter: [] = []
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `query getTickets(
        $where: TicketResolver_GetTickets_FilterInputType, 
        $paginate: PaginatorArgs,
        $customFilter:TicketCustomFilterType!
             ) {
            getTickets(where: $where, paginate: $paginate,customFilter:$customFilter) {
                data {
                  id
                  uuid
                  ticketType
                  machineId
                  parentId
                  chatDescription
                  ticketSourceId
                  issueReportedOtherInput
                  machineServiceOtherInput
                  operationOtherInput
                  marketingOtherInput
                  scheduleStartDate
                  scheduleEndDate
                  travelingStartTime
                  travelingEndTime
                  workStartTime
                  isFilterReplaced 
                  isSanitizerReplaced
                  media{
                    url
                    type
                  }
                  signatureName
                  signatureImage {
                    url
                    type
                  }
                  reportedAt
                  assignToId
                  priority
                  issueType {
                    id
                    name
                  }
                  diagnosisDone {
                    id
                    name
                  }
                  diagnosisOtherInput
                  reportedByType
                  reportedById
                  reportedByName
                  reportedByPhone
                  reportedByEmail
                  reportedByUser {
                    firstName
                    lastName
                  }
                  serviceType
                  issuesReported {
                    id
                    name
                  }
                  machineServices {
                    id
                    name
                  }
                  machineServicesDone {
                    id
                    name
                  }
                  operationsDone {
                    id
                    name
                  }
                  operations {
                    id
                    name
                  }
                  marketingOrOthers {
                    id
                    name
                  }
                  marketingMaterials {
                    id
                    name
                  }
                  comment
                  lastStatusComment
                  lastAssigneToComment
                  status
                  howTicketClosed
                  closingDateAndTime
                  timeSpentOnTask
                  travelTime
                  customerSatisfaction
                  cleanliness
                  trafficRate
                  staffEnthusiasm
                  createdById
                  createdAt
                  updatedById
                  updatedAt
                  machine {
                    id
                    serialNumber
                    name
                    deploymentDate
                    locationId
                    deployingPersonId
                    softwareVersion
                    hardwareVersion
                    createdById
                    updatedById
                    isActive
                    location{
                      name
                    }
                  }
                  ticketSource {
                    id
                    name
                    createdAt
                    updatedAt
                  }
                  assignTo {
                    id
                    firstName
                    lastName
                  }
                  createdBy {
                    id
                    firstName
                    lastName
                  }
                  subTasks {
                    id
                    uuid
                    ticketType
                    machineId
                    parentId 
                    issueReportedOtherInput
                    machineServiceOtherInput
                    operationOtherInput
                    marketingOtherInput
                    ticketSourceId
                    media{
                      url
                      type
                    }
                    reportedAt
                    assignToId
                    priority
                    issueType {
                      id
                      name
                    }
                    machineServices {
                      id
                      name
                    }
                    operations {
                      id
                      name
                    }
                    machineServicesDone {
                      id
                      name
                    }
                    operationsDone {
                      id
                      name
                    }
                    marketingOrOthers {
                      id
                      name
                    }
                    comment
                    lastStatusComment
                    lastAssigneToComment
                    status
                    howTicketClosed
                    closingDateAndTime
                    timeSpentOnTask
                    travelTime
                    customerSatisfaction
                    cleanliness
                    trafficRate
                    staffEnthusiasm
                    createdById
                    createdAt
                    updatedById
                    updatedAt
                    machine {
                      id
                      serialNumber
                      name
                    }
                    ticketSource {
                      id
                      name
                      createdAt
                      updatedAt
                    }
                    assignTo {
                      id
                      firstName
                      lastName
                    }
                    createdBy {
                      id
                      firstName
                      lastName
                    }
                  }
                }
                totalPages 
                total
                perPage
                page 
          }
      }`,
      variables: { where, paginate, customFilter },
    },
  });
  return data?.data?.data?.getTickets;
}
export async function getTicket(id: string) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query($id: String!) {
                getTicket(id: $id) {
                  id
                  uuid
                  ticketType
                  machineId
                  parentId
                  ticketSourceId
                  issueReportedOtherInput
                  machineServiceOtherInput
                  operationOtherInput
                  marketingOtherInput
                  travelingStartTime
                  travelingEndTime
                  workStartTime
                  reportedAt
                  assignToId
                  issueTypeId
                  priority
                  reportedByType
                  reportedById
                  reportedByName
                  reportedByPhone
                  reportedByEmail
                  blenderId
                  boardId
                  scheduleStartDate
                  scheduleEndDate
                  travelingStartTime
                  travelingEndTime
                  workStartTime
                  isFilterReplaced 
                  isSanitizerReplaced
                  oldMachineShiftToLocationId
                  convertToLabTicket
                  assignedNewMachineId
                  assignedNewMachine {
                    id
                    name
                  }
                  oldMachineShiftToLocation {
                    id
                    name
                  }
                  board {
                    title
                    id
                  }
                  blender {
                    title
                    id
                  }
                  replaceNewTubeDoors1mmShorter
                  reportedByUser {
                    firstName
                    lastName
                  }
                  issueType {
                    id
                    name
                  }
                  serviceType
                  issuesReported {
                    id
                    name
                  }
                  machineServices {
                    id
                    name
                  }
                  operations {
                    id
                    name
                  }
                  diagnosisDone {
                    id
                    name
                  }
                  machineServicesDone {
                    id
                    name
                  }
                  operationsDone {
                    id
                    name
                  }
                  marketingOrOthers {
                    id
                    name
                  }
                  marketingMaterials {
                    id
                    name
                  }
                  diagnosisOtherInput
                  comment
                  chatDescription
                  lastStatusComment
                  lastAssigneToComment
                  status
                  howTicketClosed
                  closingDateAndTime
                  timeSpentOnTask
                  travelTime
                  customerSatisfaction
                  cleanliness
                  trafficRate
                  staffEnthusiasm
                  createdById
                  createdAt
                  updatedById
                  updatedAt
                  media{
                    url
                    type
                  }
                  signatureName
                  signatureImage {
                    url
                    type
                  }
                  machine {
                    id
                    uuid
                    serialNumber
                    name
                    locationId
                    location{
                      latitude
                      longitude
                    }
                  }
                  ticketSource {
                    id
                    name
                  }
                  assignTo {
                    id
                    firstName
                    lastName
                  }
                  createdBy {
                    id
                    firstName
                    lastName
                  }
                  updatedBy {
                    id
                    firstName
                    lastName
                  }
                  history{
                    action
                    difference
                    createdAt
                  }
                  subTasks {
                    id
                    uuid
                    ticketType
                    machineId
                    parentId
                    ticketSourceId
                    issueReportedOtherInput
                    machineServiceOtherInput
                    operationOtherInput
                    marketingOtherInput
                    reportedAt
                    assignToId
                    issueTypeId
                    priority
                    issueType {
                      id
                      name
                    }
                    serviceType
                    machineServices {
                      id
                      name
                    }
                    operations {
                      id
                      name
                    }
                    marketingOrOthers {
                      id
                      name
                    }
                    machineServicesDone {
                      id
                      name
                    }
                    operationsDone {
                      id
                      name
                    }
                    comment
                    lastStatusComment
                    lastAssigneToComment
                    status
                    howTicketClosed
                    closingDateAndTime
                    timeSpentOnTask
                    travelTime
                    customerSatisfaction
                    cleanliness
                    trafficRate
                    staffEnthusiasm
                    oldMachineShiftToLocationId
                    convertToLabTicket
                    oldMachineShiftToLocation {
                      id
                      name
                    }
                    createdById
                    createdAt
                    updatedById
                    updatedAt
                    media{
                      url
                      type
                    }
                    machine {
                      id
                      uuid
                      serialNumber
                      name
                      locationId
                    }
                    ticketSource {
                      id
                      name
                    }
                    assignTo {
                      id
                      firstName
                      lastName
                    }
                    createdBy {
                      id
                      firstName
                      lastName
                    }
                    updatedBy {
                      id
                      firstName
                      lastName
                    }
                  }
                }
            }
            `,
      variables: { id },
    },
  });
  return data?.data?.data?.getTicket;
}
export async function getMachineService() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query{
                getMachineService {
                  id
                  name
                }
              }
            `,
      variables: {},
    },
  });
  return data?.data?.data?.getMachineService;
}
export async function getIssueReported() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query{
              getIssueReported {
                id
                name
                createdAt
                updatedAt
              }
              }
            `,
      variables: {},
    },
  });
  return data?.data?.data?.getIssueReported;
}
export async function getMarketing() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
              query{
                getMarketing {
                  id
                  name
                }
              }
              `,
      variables: {},
    },
  });
  return data?.data?.data?.getMarketing;
}
export async function getOperations() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query{
                  getOperations {
                    id
                    name
                  }
                }
                `,
      variables: {},
    },
  });
  return data?.data?.data?.getOperations;
}
export async function getTicketSources() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                  query {
                    getTicketSources {
                      id
                      name
                    }
                  }
                  `,
      variables: {},
    },
  });
  return data?.data?.data?.getTicketSources;
}
