import React, { Component, createRef } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import Modal from "react-responsive-modal";
import toast, { Toaster } from "react-hot-toast";
import { KTSVG } from "../../../../_metronic/helpers";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { getTubeShields, updateTubeShield } from "../../../models/_tubeShield";
import { MediaImageModal } from './MediaImageModal';
import { handleMediaFileUpload } from "./CreateTubeShield";
import { FOLDER_NAME } from "../../../../_metronic/helpers/enums";
export default class TubeShieldList extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      tableRef: createRef(),
      resetFrom: createRef(),
      resetTo: createRef(),
      filterLoading: false,
      editOpen: false,
      addLoading: false,
      filterToDate: "",
      filterFromDate: "",
      filterName: "",
      currentlyOpenRecord: {},
      tubeShieldListObj: {},
      name: "",
      description:"",
      mediaFile:"",
      previewUrl:"",
      mediaImageModal: false,
    };
  }
  async componentWillMount() { }
  async componentDidUpdate() { }

  async componentDidMount() {
    var _ = this;
 
    $("#tubeShiledTable").on("click", ".editBtn", function () {
      const record = $(this).attr("data-recordId");
      _.editOpen(record);
    });
    $("body").on("click", ".mediaImage", async function () {
      const record = $(this).attr("data-recordId");
      _.setState({
          mediaImageModal: true,
          currentlyOpenRecord: record,
      });
    });
    const rows = await this.getData();
    this.renderDataTable(rows);
  }

  handleMediaImageClose = () => {
    this.setState({
        mediaImageModal: false,
        currentlyOpenRecord: {},
    });
  };

  // rendering datatable jquery
  renderDataTable = (rows: any) => {
    const getPermissions: any = localStorage.getItem("permissions");
    const permissions = getPermissions || [];
    $(document).ready(function () {
      $("#tubeShiledTable").DataTable().destroy();
      let table = $("#tubeShiledTable").DataTable({
        data: rows,
        columns: [
          {
            name: "index",
            render: function (data, type, row) {
              return row.id;
            },
          },
          {
            name: "media-image",
            render: function (data, type, row) {
              const mediaUrls = row.mediaUrl;
              return mediaUrls ?  `
              <div style="display: flex; flex-wrap: wrap; gap: 5px; max-width: 100%; overflow: auto;">
                        <img 
                          data-recordId="${mediaUrls}"
                          class="mediaImage"
                          src="${mediaUrls}"
                          alt="tubeShiledMedia"
                          style="max-width: 90px; height: auto; margin-right: 5px; margin-top: 5px; cursor: pointer; object-fit: contain;"
                        />
              </div>
            ` : "";
            },
          },
          {
            name: "name",
            render: function (data, type, row) {
              return row.name;
            },
          },
          {
            name: "description",
            render: function (data, type, row) {
              return row.description;
            },
          },
          {
            name: "createdAt",
            render: function (data, type, row) {
              return row.createdAt;
            },
          },
          
          {
            name: "action",
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-tube-shield") === true) {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to edit Tube SHield"><button class="btn btn-secondary btn editBtn p-2 px-4 m-1" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-pencil' style='font-size:1rem;'></i></button></div>";
              }

              return returnData;
            },
          },
        ],
        pagingType: "full_numbers",
        pageLength: 20,
        processing: true,
        dom: "Bfrtip",
        destroy: true,
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
      });
      if (permissions?.includes("update-tube-shield") === false) {
        table.column(4).visible(false);
      }
      $(".dt-buttons").remove();
    });
  };

  // fetch data into datatable
  getFilterRecords = async () => { };
  getData = async (isReset = false) => {
    let filter: any = {};
    if (this.state.filterName) {
      filter["name"] = { like: "%" + this.state.filterName + "%" };
    }
    if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
      filter["created_at"] = {};
    }
    if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
      filter["created_at"]["gt"] = new Date(
        this.state.filterFromDate
      ).toISOString();
    } else if (
      this.state.filterFromDate === "" &&
      this.state.filterToDate !== ""
    ) {
      filter["created_at"]["lt"] = new Date(
        this.state.filterToDate
      ).toISOString();
    } else if (
      this.state.filterFromDate !== "" &&
      this.state.filterToDate !== ""
    ) {
      filter["created_at"]["between"] = [
        new Date(this.state.filterFromDate).toISOString(),
        new Date(this.state.filterToDate).toISOString(),
      ];
    }
    const records = await getTubeShields(filter);

    const rows = [];
    const tubeShieldListObj: any = {};
    let counter = 0;
    for (const record of records.data) {
      counter++;
      const tempRecord = {
        id: counter,
        recordId: record.id,
        mediaUrl: record.mediaUrl,
        name: record.name,
        description:record.description,
        createdAt: record.createdAt,
        record: record,
      };
      rows.push(tempRecord);
      tubeShieldListObj[record.id] = tempRecord;
    }

    this.setState({
      tubeShieldListObj: tubeShieldListObj,
    });
    return rows;
  };
  //edit ticket open
  editOpen = (recordId: any) => {
    const record = this.state.tubeShieldListObj[recordId];
    this.setState({
      name: record.name,
      description:record.description,
      currentlyOpenRecord: record.record,
      previewUrl: record.mediaUrl,
      editOpen: true,
    });
  };
  editClose = () => {
    this.setState({
      currentlyOpenRecord: "",
      name: "",
      description:"",
      previewUrl:"",
      editOpen: false,
      loading: false,
    });
  };

  submitEdit = async (record: any) => {
    let hasErrors = false;
    if (this.state.name === "") {
      hasErrors = true;
      toast.error("Name is required!");
    }
    if (hasErrors) {
      return false;
    }
    this.setState({
      loading: true,
    });

    let updateData: any = {
      name: this.state.name,
      description: this.state.description,
      mediaUrl: this.state.previewUrl
    };
    if(this.state.mediaFile){
      const url = await handleMediaFileUpload(this.state.mediaFile,FOLDER_NAME.TUBE_SHIELD_IMAGES);
      updateData.mediaUrl = url;
    }

    const id = record.id;
    const result = await updateTubeShield(updateData, id);

    if (result?.data?.data?.updateTubeShield) {
      toast.success("Tube Shield updated successfully");
      this.setState({
        loading: false,
        previewUrl: null,
        mediaFile: null,
      });
      this.editClose();
      const rows = await this.getData();
      this.renderDataTable(rows);
    } else {
      this.setState({
        loading: false,
      });
      const messages = result?.data?.errors.map((e: any) => e.message);
      toast.error(messages.join(","));
    }
  };


  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    const rows = await this.getData();
    this.renderDataTable(rows);
    this.setState({
      addLoading: false,
    });
    return false;
  };
  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });
    // eslint-disable-next-line
    this.state.resetFrom.current.state.inputValue = "";
    // eslint-disable-next-line
    this.state.resetTo.current.state.inputValue = "";

    this.setState({
      filterLoading: false,
      filterToDate: "",
      filterFromDate: "",
      filterName: "",
    });
    await this.getFilterRecords();
    const rows = await this.getData();
    this.renderDataTable(rows);
    return false;
  };

  render() {
    return (
      <div>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
              Tube Shield List
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3">
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">Tube Shield List</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row align-items-center justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                <button
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#board-filter"
                  aria-expanded="false"
                  aria-controls="board-filter"
                  className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center"
                >
                  <KTSVG
                    path="/media/icons/duotune/general/gen031.svg"
                    className="svg-icon-4 me-1"
                  />
                  Filter
                </button>
              </div>
            </div>
            <div
              className="collapse"
              id="board-filter"
              aria-labelledby="board-filter"
            >
              <div className="row mt-6">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Created At (From Date)
                  </label>
                  <Datetime
                    ref={this.state.resetFrom}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    initialValue={this.state.filterFromDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterFromDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">
                    Created At (To Date)
                  </label>
                  <Datetime
                    ref={this.state.resetTo}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    initialValue={this.state.filterToDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterToDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Name</label>

                  <input
                    autoComplete="off"
                    className="form-control"
                    name="filterName"
                    value={this.state.filterName}
                    onChange={(event: any) => {
                      this.setState({
                        filterName: event.target.value,
                      });
                    }}
                  />
                </div>
               
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.searchData}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.addLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.addLoading && (
                      <span className="indicator-label">ADD FILTER</span>
                    )}
                  </button>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.removeSearch}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.filterLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.filterLoading && (
                      <span className="indicator-label">RESET FILTER</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <table
                    id="tubeShiledTable"
                    className="table align-items-center display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Sr No.
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">Media Image</th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Name
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Description
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created At
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <Modal
            open={this.state.editOpen}
            onClose={this.editClose}
            role="modal"
            center
            classNames={{
              root: "content-wrapper",
            }}
          >
            <div className="modal-header p-2 pb-4 mb-4">
              <h4 className="modal-title fs-1">Edit Tube Shield</h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12 mt-2 pt-2 w-100">
                  <label className="form-label fs-4 required fw-bold">
                    Name
                  </label>
                  <input
                    className="form-control"
                    name="name"
                    id="name"
                    autoComplete="off"
                    defaultValue={this.state.name}
                    onChange={(event: any) => {
                      this.setState({
                        name: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="col-12 mt-2 pt-2 w-100">
                  <label className="form-label fs-4  fw-bold">
                    Description
                  </label>
                  <textarea
                      className="form-control"
                      name="description"
                      id="description"
                      defaultValue={this.state.description}
                      onChange={(event: any) => {
                        this.setState({
                          description: event.target.value,
                        });
                      }}
                    />
                </div>
                <div className="col-12 mt-2 pt-2 w-100">
                  <label className="form-label fs-4  fw-bold">
                    Media Image
                  </label>
                  <div className="d-flex align-items-center">
                  <label htmlFor="mediaFile" className="fs-4">
                  <input
                    type="file"
                    id="mediaFile"
                    accept="image/*"
                    placeholder="Upload media file"
                    className="d-none"
                    onChange={(event:any) => {
                      const file = event.currentTarget.files ? event.currentTarget.files[0] : null;
                      if (file) {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          this.setState({
                            mediaFile: file,
                            previewUrl: e.target?.result,
                          });
                        };
                        reader.readAsDataURL(file);
                      }
                      
                    }}
                        />
                    <span
                          className="btn btn-bg-light text-gray-700 fs-4 w-100 mt-2 ms-2 fs-5 px-5 py-3 w-100"
                          style={{ cursor: "pointer" }}
                        >
                          Upload Media File
                        </span>
                        </label>
                  </div>
                </div>
                {this.state.previewUrl && (
                  <>
                    <div
                      className="mt-4 position-relative"
                      style={{ display: "inline-block", maxWidth: "200px" }}
                    >
                      <img
                        src={this.state.previewUrl}
                        alt="Preview"
                        className="img-thumbnail"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "200px",
                        }}
                      />
                      <i
                        className="bi bi-x-lg text-danger position-absolute"
                        style={{
                          cursor: "pointer",
                          fontSize: "19px",
                        }}
                        onClick={() => {
                          this.setState({
                            mediaFile: null,
                            previewUrl: null,
                          });
                        }}
                      ></i>
                    </div>
                  </>
                )}
                
              </div>
            </div>
            <div className="modal-footer mt-6 p-0 justify-content-start">
              <button
                type="submit"
                onClick={() => {
                  return this.submitEdit(this.state.currentlyOpenRecord);
                }}
                className="btn btn-primary me-4 fs-4 p-0 px-6 py-3 mt-6"
              >
                {this.state.loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: "block" }}
                  >
                    Please Wait..
                    <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                  </span>
                )}
                {!this.state.loading && (
                  <span className="indicator-label">UPDATE</span>
                )}
              </button>
            </div>
          </Modal>
          {this.state.mediaImageModal === true && (
            <MediaImageModal
                currentlyOpenRecord={this.state.currentlyOpenRecord}
                mediaImageModal={this.state.mediaImageModal}
                handleMediaImageClose={this.handleMediaImageClose}
            />
          )}
          <Toaster
            position="bottom-left"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              className: "",
              duration: 3000,
            }}
          />
        </div>
      </div>
    );
  }
}
