import { Component, Suspense, createRef } from "react";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import Moment from "moment";
import $ from "jquery";
import * as _ from "lodash";
import {
  getCustomers,
  getDistributor,
  updateCustomerStatus,
} from "../../models/_customer";
import Select from "react-select";
import { KTSVG } from "../../../_metronic/helpers";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { DownloadExcel } from "../ExcelHelper";
import ReactSelect from "react-select";
import { EditCustomer } from "./EditCustomer";
import { AUTH_LOCAL_STORAGE_KEY } from "../../../app/modules/auth";
import Columns from '../../../_metronic/helpers/Columns';
import { getModuleDataDisplaySettingByKey, updateModuleDataDisplaySettingByKey } from "../../models/_user";
import { Option } from '../../../_metronic/helpers/MySelect';
import { Tooltip } from "react-tooltip";
import { ReactivateCustomerModal } from "./ReActivateCustomerModal";
import moment from "moment";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

class CustomersList extends Component<any, any> {
  readonly props: any;

  constructor(props: any, state: any) {
    super(props);
    let searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    const currentPage= searchParams.get('page');
    const fromDate = searchParams.get('from_date');
    const toDate = searchParams.get('to_date');
    const customerName = searchParams.get('customer_name');
    const isVip = searchParams.get('is_vip');
    const status = searchParams.get('is_active');
    const distributerIdString = searchParams.get('distributor_id');
    const distributerIdsArray = distributerIdString ? distributerIdString.split(',').map((x) => x) : "";
    const accountTypeIdString = searchParams.get('account_type_id');
    const accountIdsArray = accountTypeIdString ? accountTypeIdString.split(',').map((x) => x) : "";

    this.state = {
      addLoading: false,
      filterLoading: false,
      resetDistributor: createRef(),
      resetAccountType: createRef(),
      resetIsVip: createRef(),
      resetStatus: createRef(),
      resetFrom: createRef(),
      resetTo: createRef(),
      downloadLoading: false,
      filterFromDate: fromDate || "",
      filterToDate: toDate || "",
      filterDistributer: distributerIdsArray || "",
      filterName: customerName || "",
      customerEditModal: false,
      currentCustomerId: id || "",
      filterStatus: status || "true",
      filterAccountType: accountIdsArray || "",
      filterIsVip: isVip || undefined,
      loading: false,
      distributorSelected:null,
      accountTypeSelected:null,
      customersListObj: {},
      customersList: [],
      currentDistributor: "",
      distributorDB: [],
      customerDB: [],
      customerStatus: false,
      finalUrl:'',
      page: currentPage || 0,
      perPage: 50,
      statusDB: [
        {
          id: "true",
          name: "Active",
        },
        {
          id: "false",
          name: "Inactive",
        },
        {
          id:"all",
          name:"All"
        }
      ],
      accountTypeDB: [
        {
          id: "CHAIN",
          name: "Chain",
        },
        {
          id: "INDIVIDUAL",
          name: "Individual",
        },
      ],
      contactTypeDB: [
        {
          id: "PERSONAL",
          name: "Personal",
        },
        {
          id: "OFFICE",
          name: "Office",
        },
      ],
      isVipDB: [
        {
          id: "true",
          name: "Yes",
        },
        {
          id: "false",
          name: "No"
        },
        {
          id: "all",
          name: "All"
        }
      ],
      moduleKeyName: 'customer_list',
      selectedColumns: [],
      columnData: [
        { id: 0, label: 'Company ID', key: 'index' },
        { id: 1, label: 'Customer Name', key: 'customerName' },
        { id: 2, label: 'Company Name', key: 'companyName' },
        { id: 3, label: 'Contact Name', key: 'contactName' },
        { id: 4, label: 'Contact Name Role', key: 'contactNameRole' },
        { id: 5, label: 'Contact Phone Type', key: 'contactPhoneType' },
        { id: 6, label: 'Distributor', key: 'distributor' },
        { id: 7, label: 'Account Type', key: 'accountType' },
        { id: 8, label: 'Second Contact Name', key: 'secondContactName' },
        { id: 9, label: 'Second Contact Role', key: 'secondContactNameRole' },
        { id: 10, label: 'Second Contact Phone Type', key: 'secondContactPhoneType' },
        { id: 11, label: 'Created At', key: 'createdAt' },
        { id: 12, label: 'Vip', key: 'isVip' },
        { id: 13, label: 'Customer Status', key: 'isActive' },
        { id: 14, label: 'Action', key: 'action' }
      ]
    };
  }

  async componentWillMount() {}

  async componentDidUpdate() {
    await this.handleCustomerFilterSet();
  }

  async componentDidMount() {
    var _ = this;
    $("body").on("change", ".togBtn", function () {
      const record = $(this).attr("data-recordId");
      const status = $(this).attr("data-status");
      if(status === 'false') {
        _.setState({
          changeReactivateModal: true,
          currentCustomerId: record
        })
      } else {
        _.customerStatus(record, false, false);
        _.setState({
          currentCustomerId: record,
        })
      }
      return false;
    });

    $("body").on("click", ".editBtn", function () {
      const record = $(this).attr("data-recordId");
      _.setState({
        customerEditModal: true,
        currentCustomerId: record 
      })
    });

    $("body").on("click", ".reActivateModal", function () {
      const record = $(this).attr("data-recordId");
      _.setState({
        changeReactivateModal: true,
        currentCustomerId: record
      })
    });

    await this.getFilterRecords();
    this.renderDataTable([]);

  }
  getFilter = () => {
    let filter: any = {};
    if (this.state.filterDistributer.length > 0) {
      filter["distributor_id"] = { in: this.state.filterDistributer };
    }
    if (this.state.filterAccountType.length > 0) {
      filter["account_type"] = { in: this.state.filterAccountType };
    }
    if (this.state.filterName) {
      filter["name"] = { like: "%" + this.state.filterName + "%" };
    }
    if (this.state.filterStatus === "true") {
      filter["is_active"] = { eq: true };
    } else if (this.state.filterStatus === "false") {
      filter["is_active"] = { eq: false };
    }
    if(this.state.filterStatus === "all"){
      delete filter["is_active"]
     }
    if (this.state.filterIsVip === "true") {
      filter["is_vip"] = { eq: true };
    } else if (this.state.filterIsVip === "false") {
      filter["is_vip"] = { eq: false };
    }
    if (this.state.filterIsVip === "all"){
      delete filter["is_vip"]
    }
    if (this.state.filterFromDate !== "" || this.state.filterToDate !== "") {
      filter["created_at"] = {};
    }
    if (this.state.filterFromDate !== "" && this.state.filterToDate === "") {
      filter["created_at"]["gt"] = new Date(
        this.state.filterFromDate
      ).toISOString();
    } else if (
      this.state.filterFromDate === "" &&
      this.state.filterToDate !== ""
    ) {
      filter["created_at"]["lt"] = new Date(
        this.state.filterToDate
      ).toISOString();
    } else if (
      this.state.filterFromDate !== "" &&
      this.state.filterToDate !== ""
    ) {
      filter["created_at"]["between"] = [
        new Date(this.state.filterFromDate).toISOString(),
        new Date(this.state.filterToDate).toISOString(),
      ];
    }
    return filter
  }
  getURL = (baseURL: string, customerListFilter?: any) => {
    const isApplyDistributerFilter = customerListFilter?.distributor_id?.in || this.state.filterDistributer;
    const isApplyFromDateFilter = this.state.filterFromDate;
    const isApplyToDateFilter = this.state.filterToDate;
    const isApplyCustomerNameFilter = this.state.filterName;
    const isApplyStatusFilter = this.state.filterStatus;
    const isApplyAccountTypeFilter = this.state.filterAccountType;
    const isApplyIsVipFilter = this.state.filterIsVip;
   
    if (isApplyFromDateFilter) {
      let date = moment(isApplyFromDateFilter).format('YYYY-MM-DD HH:mm')
      let encodedDate = encodeURIComponent(date);
      baseURL += `&from_date=${encodedDate}`
    }
    if (isApplyToDateFilter) {
      let date = moment(isApplyToDateFilter).format('YYYY-MM-DD HH:mm')
      let encodedDate = encodeURIComponent(date);
      baseURL += `&to_date=${encodedDate}`
    }
    if (isApplyDistributerFilter && isApplyDistributerFilter.length > 0) {
      const distributerIds = isApplyDistributerFilter.join(',');
      baseURL += `&distributor_id=${distributerIds}`
    }
    if (isApplyAccountTypeFilter) {
      const accountTypeIds =isApplyAccountTypeFilter.join(',');
      baseURL += `&account_type_id=${accountTypeIds}`
    }
    if (isApplyCustomerNameFilter) {
      let decodedCustomerName = decodeURIComponent(isApplyCustomerNameFilter); 
      let encodedCustomerName = encodeURIComponent(decodedCustomerName); 
      baseURL += `&customer_name=${encodedCustomerName}`; 
    }
    
    if (isApplyStatusFilter === "true" || isApplyStatusFilter === "false" || isApplyStatusFilter === "all") {
      baseURL += `&is_active=${isApplyStatusFilter}`
    }
  
    if (isApplyIsVipFilter === "true"  || isApplyIsVipFilter === "false" || isApplyIsVipFilter === "all") {
      baseURL += `&is_vip=${isApplyIsVipFilter}`
    }
    return baseURL;
   }
  // rendering datatable jquery
  renderDataTable = async (rows: any) => {
    var _this = this;
    const getPermissions: any = localStorage.getItem("permissions");
    const permissions = getPermissions || [];
    const filter = this.getFilter();
    await this.getColumnData();
    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    let authToken = "";
    if (lsValue) {
      const user = JSON.parse(lsValue);
      authToken = user.access_token;
    }

    $(document).ready(function () {
      $("#customersTable").DataTable().destroy();
      // eslint-disable-next-line
      const table = $("#customersTable").DataTable({
        pagingType: "full_numbers",
        serverSide: true,
        pageLength: _this.state.perPage,
        paging: true,
        searching: false,
        "ajax": {
          "url": process.env.REACT_APP_API_URL,
          "type": "POST",
          "contentType": "application/json",
          "beforeSend": function (xhr) {
            xhr.setRequestHeader('Authorization',
                "Bearer " + authToken);
        },
        "data": function (d: any) {
          var gql = `query  getCustomers(  
            $where: CustomersResolver_GetCustomers_FilterInputType
            $paginate:PaginatorArgs
            ) {
                getCustomers(where: $where ,paginate: $paginate) {
                    data {
                      id
                      uuid
                      name
                      customerCompanyName
                      customerContactName
                      customerContactPhoneNumber
                      customerContactEmail
                      customerContactNameRole
                      accountType
                      distributorId
                      customerSecondContact
                      customerSecondContactName
                      customerSecondContactPhoneNumber
                      customerSecondContactEmail
                      customerSecondContactNameRole
                      createdById
                      createdAt
                      updatedById
                      updatedAt
                      isActive
                      typeOfContactPhoneNumber
                      typeOfSecondContactPhoneNumber
                      isVip
                      distributor{
                        id
                        name
                      }
                      createdBy{
                        id
                        firstName
                       lastName
                       }
                    }
                    totalPages
                    total
                    perPage
                    page 
                  }
          }`;
          const currentPage = (d?.start) ?(d.start / d.length) + 1 : 1;
            const length = d?.length > 0 ? d.length : 50;
            var query = {
              "operationName":null,
              "query":gql,
              "variables": {
                "where": filter,
                paginate: {
                  
                    "page": currentPage,
                    "per_page": length
                  
                }                   
              }
            };
            
            return JSON.stringify(query);
        },
        "dataSrc": function (json) {
            
          json.recordsTotal = json.data.getCustomers.total;
          json.recordsFiltered = json.data.getCustomers.total;        

          const rows: any[] = [];
          const records = json.data.getCustomers.data;
          for (const record of records) {
            let createdAt = "";
            if (record.createdAt) {
              createdAt = Moment.utc(record.createdAt)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
            }
            let updatedAt = "";
            if (record.updatedAt) {
              updatedAt = Moment.utc(record.updatedAt)
                .local()
                .format("YYYY-MM-DD HH:mm:ss");
            }
            const tempRecord: any = {
              id: record.uuid,
              recordId: record.id,
              customerName: record.name,
              companyName: record.customerCompanyName,
              contactName: record.customerContactName,
              customerContactPhoneNumber: record.customerContactPhoneNumber,
              contactPhoneType: _.capitalize(
                _.lowerCase(record.typeOfContactPhoneNumber)
              ),
              customerContactEmail: record.customerContactEmail,
              contactNameRole: record.customerContactNameRole,
              distributor: record.distributor?.name ?? "",
              customerSecondContact: record.customerSecondContact,
              secondContactName: record.customerSecondContactName,
              customerSecondContactPhoneNumber:
                record.customerSecondContactPhoneNumber,
              secondContactPhoneType: _.capitalize(
                _.lowerCase(record.typeOfSecondContactPhoneNumber)
              ),
              customerSecondContactEmail: record.customerSecondContactEmail,
              secondContactNameRole: record.customerSecondContactNameRole,
              accountType: _.capitalize(_.lowerCase(record.accountType)),
              isVip: record.isVip === true ? 'Yes' : 'No',
              createdBy: record.createdBy?.firstName,
              createdAt,
              updatedAt,
              isActive: record.isActive,
              record: record,
            };

            const subRecords = record.subTasks ?? "";
            for (const subRecord of subRecords) {
              subRecord["record"] = { ...subRecord };
            }
            rows.push(tempRecord);
          }
          if (rows && rows.length !== 0) {
            _this.setState({
              customersList: rows
            })
          }
          return rows;
        }    
      },
      "initComplete": function (settings, json) {
        table.page(Number(_this.state.page)).draw(false);

      },
        // data: rows,
        columns: [
          {
            name: "index",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('index'),
            render: function (data, type, row) {
              let json = JSON.stringify(row);
              let jsonString = json.replaceAll("'", "")
              let returnsData =
                '<div data-bs-toggle="tooltip" title="Click here to view customer"><a class="clickable_entry" data-value=\'' + jsonString + '\' href="/customers/list/history/' +
                row.recordId + '?page='+ _this.state.page + _this.state.finalUrl +
                '"> ' +
                row.id +
                "</div></a>";
              return returnsData;
             
            },
          },
          {
            name: "customerName",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('customerName'),
            render: function (data, type, row) {
              let returnData = ""
              if (row.isVip === 'Yes') {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to view customer"><a href="/customers/list/history/' +
                  row.recordId + '?page='+ _this.state.page + _this.state.finalUrl +
                  '"> <i class="fa fa-crown text-warning me-1"></i>' +
                  row.customerName +
                  '</a></div>'
              } else {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to view customer"><a href="/customers/list/history/' +
                  row.recordId + '?page='+ _this.state.page + _this.state.finalUrl +
                  '"> ' +
                  row.customerName +
                  "</div></a>";
              }
              return returnData;
            },
          },
          {
            name: "companyName",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('companyName'),
            render: function (data, type, row) {
              return row.companyName;
            },
          },
          {
            name: "contactName",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('companyName'),
            render: function (data, type, row) {
              return row.contactName;
            },
          },
          {
            name: "contactNameRole",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('contactNameRole'),
            render: function (data, type, row) {
              return row.contactNameRole;
            },
          },
          {
            name: "contactPhoneType",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('contactPhoneType'),
            render: function (data, type, row) {
              return row.contactPhoneType;
            },
          },
          {
            name: "distributor",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('distributor'),
            render: function (data, type, row) {
              return row.distributor;
            },
          },
          {
            name: "accountType",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('accountType'),
            render: function (data, type, row) {
              return row.accountType;
            },
          },
          {
            name: "secondContactName",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('secondContactName'),
            render: function (data, type, row) {
              return row.secondContactName;
            },
          },
          {
            name: "secondContactNameRole",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('secondContactNameRole'),
            render: function (data, type, row) {
              return row.secondContactNameRole;
            },
          },
          {
            name: "secondContactPhoneType",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('secondContactPhoneType'),
            render: function (data, type, row) {
              return row.secondContactPhoneType;
            },
          },
          {
            name: "createdAt",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('createdAt'),
            render: function (data, type, row) {
              return row.createdAt;
            },
          },
          {
            name: "isVip",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('isVip'),
            render: function (data, type, row) {
              return row.isVip;
            },
          },
          {
            name: "isActive",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('isActive'),
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-customer") === true) {
                let isChecked =
                  row.isActive === true ? 'checked="checked"' : "";
                returnData +=
                  `
                <label class="status-switch ${row.isActive === false ? 'reActivateModal' : ''}">
                  <input type="checkbox" ${isChecked} data-recordId="${row.recordId}" data-status="${row.isActive}" class="togBtn ">
                  <div class="status-slider round "></div>
                </label>`;
              } else {
                if (row.isActive === true) {
                  returnData += '<div class="text-dark"> Active</div>';
                } else {
                  returnData += '<div class="text-dark"> Inactive</div>';
                }
              }
              return returnData;
            },
          },
          {
            name: "action",
            visible: _this.state.selectedColumns || _this.state.selectedColumns.includes('action'),
            render: function (data, type, row) {
              let returnData = "";
              if (permissions?.includes("update-customer") === true) {
                returnData +=
                  '<div data-bs-toggle="tooltip" title="Click here to edit customer"><button class="btn btn-secondary editBtn p-2 px-4 m-1" data-recordId=\'' +
                  row.recordId +
                  "'><i class='fa fa-pencil' style='font-size:1rem;'></i></button></div>";
              }
              returnData +=
                '<div data-bs-toggle="tooltip" title="Click here to view customer"><a class="btn btn-primary p-2 px-4 m-1" href="/customers/list/history/' +
                row.recordId + '?page='+ _this.state.page + _this.state.finalUrl +
                '"><i class="fa fa-eye"></i></a></div>';
              return returnData;
            },
          },
        ],
        processing: true,
        dom: "Bfrtip",
        order: [[0, "desc"]],
        lengthMenu: [
          [10, 20, 30, 50, -1],
          [10, 20, 30, 50, "All"],
        ],
        columnDefs: [
          {
            targets: 0,
            render: function (data, type, row, meta) {
              return type === "export" ? meta.row + 1 : data;
            },
          },
        ],
        createdRow: function (row, data, rowIndex) {
          var title: any = [];
          $("#customersTable thead tr th").each(function () {
            title.push($(this).text());
          });
          $.each($("td", row), function (colIndex) {
            $(this).attr("data-title", title[colIndex]);
          });
        },
        
      });
      table.on('page', function () {
        const currentPage = table.page.info();

        _this.setState({
          page: currentPage.page
        })

      });
      $(document)[0].oncontextmenu = function() { return false; }

      $('#customersTable tbody').mousedown( function (e) { 
        e.preventDefault()
        if(e.buttons === 1) return true;
        e.preventDefault();
        var top = e.clientY;
        

        var left = e.clientX;
        const recordString = $(e.target).parents('tr').find('a.clickable_entry').attr('data-value');
        let record;
        if(recordString) {
         
          record = JSON.parse(recordString)
        }
        if(record) {
          $('#view_record_link').attr('href',`/customers/list/history/${record.recordId}?page=${_this.state.page}${_this.state.finalUrl}`)
          $('#edit_record_link').attr('data-recordId', record.recordId)

        
       
            $("#context-menu").css({
              top: top,
             
              left: left
            }).show();
          
        
        }
        
        return false;
        
      }) 
      $('html').click(function() {
        $("#context-menu").hide();
          })
 
            $("#context-menu a").click(function(e){
            
             
          });
          _this.displaySelectedColumns(table);

        });
 

    $(".dt-buttons").remove();
  };

  async getFilterRecords() {
    const distributorRecord = await getDistributor();
    const distributorIdResults: any = distributorRecord
      ? distributorRecord
      : [];
    distributorIdResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    distributorIdResults.push({
      id: "other",
      name: "Other",
    });
    const customerRecord = await getCustomers();
    const customerResults: any = customerRecord.data ? customerRecord.data : [];
    customerResults.sort(function (a: any, b: any) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    this.setState({
      distributorDB: distributorIdResults,
      customerDB: customerResults,
    });
  }


  handleDownloadExcel = async () => {
    this.setState({
      downloadLoading: true
    })
    const filter = this.getFilter();
    const result: any = await getCustomers(filter);
    const selectedColumns = this.state.selectedColumns || [];
    const excelRecords = [];
    for (const record of result.data) {
      const tempRecord: any = {};
      if (selectedColumns.includes('index')) tempRecord.id = record.uuid;
      if (selectedColumns.includes('customerName')) tempRecord['Customer Name'] = record.name;
      if (selectedColumns.includes('companyName')) tempRecord['Company Name'] = record.customerCompanyName;
      if (selectedColumns.includes('contactName')) tempRecord['Contact Name'] = record.customerContactName;
      if (selectedColumns.includes('contactNameRole')) tempRecord['Contact Name Role'] = record.customerContactNameRole;
      if (selectedColumns.includes('contactPhoneType')) tempRecord['Contact Phone Type'] = _.capitalize(_.lowerCase(record.typeOfContactPhoneNumber));
      if (selectedColumns.includes('distributor')) tempRecord['Distributor'] = record.distributor?.name ?? "";
      if (selectedColumns.includes('accountType')) tempRecord['AccountType'] = _.capitalize(_.lowerCase(record.accountType));
      if (selectedColumns.includes('secondContactName')) tempRecord['Second Contact Name'] = record.customerSecondContactName;
      if (selectedColumns.includes('secondContactNameRole')) tempRecord['Second Contact Name Role'] = record.customerSecondContactNameRole;
      if (selectedColumns.includes('secondContactPhoneType')) tempRecord['Second Contact Phone Type'] = _.capitalize(_.lowerCase(record.typeOfSecondContactPhoneNumber));
      if (selectedColumns.includes('createdAt')) tempRecord['Created At'] = Moment.utc(record.createdAt).local().format("YYYY-MM-DD HH:mm:ss");
      if (selectedColumns.includes('isVip')) tempRecord['Is Vip'] = record.isVip === true ? 'Yes' : 'No';
      if (selectedColumns.includes('isActive')) tempRecord['Customer Status'] = record.isActive === true ? "Active" : "In active";
    

    excelRecords.push(tempRecord);
    }
    DownloadExcel(excelRecords, "SHEET_NAME", "Customers_list");
    this.setState({
      downloadLoading: false
    })
  };

  getLabelForStatus(status:any) {
    if (status === "true") {
      return "Active";
    } else if (status === "false") {
      return "Inactive";
    } else if (status === "all") {
      return "All";
    }else {
      return "Active"; 
    }
  }
  getLabelForIsVip(status:any) {
    if (status === "true") {
      return "Yes";
    } else if (status === "false") {
      return "No";
    } else if (status === "all") {
      return "All";
    }
  }
  customerStatus = async (recordId: any, customerStatus: boolean, activateStatus: boolean) => {
    try {
      await updateCustomerStatus(recordId, customerStatus, activateStatus);
      this.refreshTable();
    } catch (e: any) {
      console.log(e);
    }
  };
  handleCloseReactivateCustomer = () => {
    this.refreshTable();
    this.setState({
      changeReactivateModal: false,
      currentCustomerId: ""
    })
  } 
  refreshTable = async () => {
    await this.getFilterRecords();
    this.renderDataTable([]);
  }
  handleCustomerClose = () => {
    this.setState({
      customerEditModal: false,
      currentCustomerId: ""
    })
  }

  // filter data submit
  searchData = async () => {
    this.setState({
      addLoading: true,
    });
    const url = this.getURL(`/customers/list?page=${this.state.page}`);
    window.history.replaceState({}, '', url);;
    this.setState({
      finalUrl: this.getURL('')
    });
  
    this.refreshTable()
    this.renderDataTable([]);
    // const rows = await this.getData();
    // this.renderDataTable(rows);
    this.setState({
      addLoading: false,
    });
    return false;
  };
  removeSearch = async () => {
    this.setState({
      filterLoading: true,
    });
 
    this.state.resetStatus.current.setValue("");
    this.state.resetIsVip.current.setValue("");
    // eslint-disable-next-line
    this.state.resetFrom.current.state.inputValue = "";
    // eslint-disable-next-line
    this.state.resetTo.current.state.inputValue = "";
    this.setState({
      filterToDate: "",
      filterFromDate: "",
      filterStatus: "",
      filterIsVip: "",
      filterName:"",
      distributorSelected:null,
      filterDistributer:"",
      accountTypeSelected:null,
      filterAccountType:""
    });
    window.history.replaceState({}, '', "/customers/list");
    await this.getFilterRecords();
    this.renderDataTable([]);
    // await this.getFilterRecords();
    // const rows = await this.getData();
    // this.renderDataTable(rows);
    this.setState({
      filterLoading: false,
    });
    return false;
  };
// async getColumnData() {
  getColumnData = async () => {
    var _ = this;
    const data = await getModuleDataDisplaySettingByKey(this.state.moduleKeyName);
    if (data) {
      _.setState({ selectedColumns: data.metaValue })
      return data.metaValue;
    }
    return [];
  }

  displaySelectedColumns = async (table: any) => {
    var _this = this;
    if (_this.state.selectedColumns.length > 0) {
      table.settings().init().columns?.map(function (val: any, key: any) {
        if (!_this.state.selectedColumns.includes(val.name)) {
          table.column(key).visible(false);
        }
        return false;
      });
    }
    $('#customersTable').show();
    return false;
  };

  handleColumnDataSelect = async (e: any) => {
    const selCol = (e.target.checked)
        ? [...this.state.selectedColumns, e.target.value]
        : this.state.selectedColumns.filter((item: string) => item !== e.target.value);
    this.setState({ selectedColumns: selCol });
  };

  handleColumnDataSubmit = async (e: any) => {
    const selectedColumns = this.state.selectedColumns;
    await updateModuleDataDisplaySettingByKey(this.state.moduleKeyName, selectedColumns);
    this.refreshTable();
  };
  handleSelectAll = () => {
    const { selectedColumns, columnData } = this.state;
    const allKeys = columnData.map((col: any) => col.key);
    if (selectedColumns.length === columnData.length) {
        this.setState({ selectedColumns: [] });
    } else {
        this.setState({ selectedColumns: allKeys });
    }
};
  handleCustomerFilterSet = async () => {
    if (this.getURL(''))
     {
      if (this.state.filterDistributer) {
        const selectDistributor = this.state.distributorDB.filter((data: any) => this.state.filterDistributer.includes(data.id))
        const finalSelectedDistributorResponse = selectDistributor.map((x: any) => {
          return {
            value: x.id,
            label: x.name
          }
        })
         if (JSON.stringify(finalSelectedDistributorResponse) !== JSON.stringify(this.state.distributorSelected)) {
          this.setState({
            distributorSelected: finalSelectedDistributorResponse,
          });
        }
      }
      if (this.state.filterAccountType) {
        const selectAccountType = this.state.accountTypeDB.filter((data: any) => this.state.filterAccountType.includes(data.id))
        const finalSelectedAccountTypeResponse = selectAccountType.map((x: any) => {
          return {
            value: x.id,
            label: x.name
          }
        })
        if (JSON.stringify(finalSelectedAccountTypeResponse) !== JSON.stringify(this.state.accountTypeSelected)) {
          this.setState({
            accountTypeSelected: finalSelectedAccountTypeResponse,
          });
        } 
      }
    }
  };
  render() {
    return (
      <Suspense>
        <div id="kt_app_toolbar" className="app-toolbar py-2 pt-4">
          <div
            id="kt_app_toolbar_container"
            className="app-container container-xxl d-block d-lg-flex d-md-flex d-xl-flex flex-stack px-3"
            style={{ maxWidth: "100%" }}
          >
            <div className="page-title d-flex flex-column justify-content-center flex-wrap">
              <h1 className="page-heading d-flex text-dark fw-bold fs-1 flex-column justify-content-center my-0">
                Customers List
              </h1>
            </div>
            <div className="d-flex align-items-center gap-2 gap-lg-3 mt-3 mt-lg-0 mt-md-0">
               <button
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#customer-filter"
                  aria-expanded="false"
                  aria-controls="customer-filter"
                  className="btn btn-bg-light collapsed fw-bold m-0 p-0 px-5 py-3 align-items-center card-title text-center">
                  <KTSVG
                    path="/media/icons/duotune/general/gen031.svg"
                    className="svg-icon-4 me-1"
                  />
                    Filter
                </button>
              <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <li className="breadcrumb-item text-muted">
                  <a href="/" className="text-muted text-hover-primary fs-5">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item">
                  <span className="bullet bg-gray-400 w-5px h-2px" />
                </li>
                <li className="breadcrumb-item text-dark fs-5">
                  Customers List
                </li>
              </ul>
            </div>
          </div>
        </div>
       
            
            <div className="collapse" id="customer-filter" aria-labelledby="customer-filter">
               <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
              <div className="row mt-6">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Created At (From Date)</label>
                  <Datetime
                    ref={this.state.resetFrom}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    value={this.state.filterFromDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterFromDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Created At (To Date)</label>
                  <Datetime
                    ref={this.state.resetTo}
                    closeOnSelect
                    dateFormat="YYYY-MM-DD"
                    timeFormat="HH:mm"
                    value={this.state.filterToDate}
                    onChange={(e: any) => {
                      const dt = e["_d"];
                      this.setState({
                        filterToDate: dt,
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Distributor</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        distributorSelected: selected,
                        filterDistributer: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.distributorSelected}
                    options={Array.from(this.state.distributorDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Account Type</label>
                  <ReactSelect
                    className="react-select"
                    classNamePrefix="my-react-select"
                    placeholder=""
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    onChange={(selected: any) => {
                      const value = selected?.map((e: any) => e.value);
                      this.setState({
                        accountTypeSelected: selected,
                        filterAccountType: value,
                      });
                    }}
                    components={{
                      Option,
                    }}
                    value={this.state.accountTypeSelected}
                    options={Array.from(this.state.accountTypeDB).map(
                      (value: any) => {
                        return {
                          value: value.id,
                          label: value.name,
                        };
                      }
                    )}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fs-4 fw-bold">Customer Name</label>
                  <ReactSearchAutocomplete
                    items={this.state.customerDB.map((customer: any) => ({ name: customer.name, id: customer.name }))}
                    onSelect={(item: any) => {
                      this.setState({
                        filterName: item.name,
                      });
                    }}
                    onSearch={(keyword) => {
                      this.setState({
                        filterName: keyword,
                      });
                    }}
                    inputSearchString={this.state.filterName }
                    autoFocus
                    styling={{
                      borderRadius: '5px',
                      zIndex: 1
                    }}
                    fuseOptions={{
                      threshold: 0.2,
                    }}
                    onClear={() => {
                      this.setState({
                        filterName: '',
                      });
                    }}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">Customer Status</label>
                  <Select
                    ref={this.state.resetStatus}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="filterStatus"
                    defaultValue={{
                      label: this.getLabelForStatus(this.state.filterStatus), 
                      value: this.state.filterStatus 
                    }}
                    placeholder=""
                    onChange={(event: any) => {
                      this.setState({
                        filterStatus: event.value,
                      });
                    }}
                    options={Array.from(this.state.statusDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3">
                  <label className="form-label fw-bold fs-4">Is VIP</label>
                  <Select
                    ref={this.state.resetIsVip}
                    className="react-select"
                    classNamePrefix="my-react-select"
                    name="filterIsVip"
                    defaultValue={{
                      label: this.getLabelForIsVip(this.state.filterIsVip), 
                      value: this.state.filterIsVip 
                    }}
                    placeholder=""
                    onChange={(event: any) => {
                      this.setState({
                        filterIsVip: event.value,
                      });
                    }}
                    options={Array.from(this.state.isVipDB).map((value: any) => {
                      return {
                        value: value.id,
                        label: value.name,
                      };
                    })}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.searchData}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.addLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.addLoading && (
                      <span className="indicator-label">ADD FILTER</span>
                    )}
                  </button>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 p-2 w-xl-25 pe-xl-5 ps-xl-5 mb-3 mb-lg-4">
                  <button
                    type="submit"
                    onClick={this.removeSearch}
                    className="btn btn-primary me-4 fs-4 p-0 w-100 px-6 py-3"
                  >
                    {this.state.filterLoading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please Wait..
                        <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                      </span>
                    )}
                    {!this.state.filterLoading && (
                      <span className="indicator-label">RESET FILTER</span>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Columns
          columnData={this.state.columnData}
          selectedColumns={this.state.selectedColumns}
          handleColumnDataSelect={this.handleColumnDataSelect}
          handleColumnDataSubmit={this.handleColumnDataSubmit}
          handleSelectAll={this.handleSelectAll}
        />
        <div className="card mt-9">
          <div className="card-body w-100 p-lg-7 p-md-7 p-5">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive p-0 pb-2 fs-6" id="data-table">
                  <div className="row align-items-center justify-content-between">
                    <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                      {/* <h3 className="btn btn-bg-light fw-bold m-0 mb-3 p-0 px-5 py-3 align-items-center card-title text-center">
                        <KTSVG
                          path="/media/icons/duotune/communication/com014.svg"
                          className="svg-icon-4 me-1"
                        />
                        Customers
                      </h3> */}
                       <button
                        type="button"
                        className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3"
                        data-bs-toggle="modal"
                        data-bs-target="#columnsModal"
                      >
                        Columns
                      </button>
                    </div>

                    <div className="col-xl-2 col-lg-3 col-md-4 col-12">
                    <button
                  onClick={this.handleDownloadExcel}
                  className="btn btn-bg-light btn-active-color-primary fw-bold btn-block w-100 p-0 px-4 py-3 mb-3"
                >
                  {this.state.downloadLoading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please Wait..
                      <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                    </span>
                  )}
                  {!this.state.downloadLoading && (
                    <span className="indicator-label"> <KTSVG
                    path="/media/icons/duotune/files/fil021.svg"
                    className="svg-icon-4 me-2"
                  />
                  Export Excel</span>
                  )}
                 
                </button>
                    </div>
                  </div>
                  <table
                    id="customersTable"
                    className="table align-items-center responsive-table display justify-content-center mb-0 w-100"
                  >
                    <thead>
                      <tr>
                        <th className="companyId text-capitalize font-weight-bolder opacity-7 ps-2">
                          Company ID
                          <Tooltip anchorSelect=".companyId" className="tooltip-list" >
                            Unique Smoodi identifier automatically created by the software
                          </Tooltip>
                        </th>
                        <th className="customerName text-capitalize font-weight-bolder opacity-7 ps-2">
                          Customer Name

                          <Tooltip anchorSelect=".customerName" className="tooltip-list">
                            Enter the brand name that the customer is using.
                          </Tooltip>
                        </th>
                        <th className="companyName text-capitalize font-weight-bolder opacity-7 ps-2">
                          Company Name

                          <Tooltip anchorSelect=".companyName" className="tooltip-list">
                            Legal name of the company
                          </Tooltip>
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Contact Name

                        </th>
                        <th className="contactNameRole text-capitalize font-weight-bolder opacity-7 ps-2">
                          Contact Name Role

                          <Tooltip anchorSelect=".contactNameRole" className="tooltip-list">
                            Specify the job title or position of the contact person
                          </Tooltip>
                        </th>
                        <th className="contactPhoneType text-capitalize font-weight-bolder opacity-7 ps-2">
                          Contact Phone Type

                          <Tooltip anchorSelect=".contactPhoneType" className="tooltip-list">
                            Specify if this phone number is for work or personal use.
                          </Tooltip>
                        </th>
                        <th className="distributor text-capitalize font-weight-bolder opacity-7 ps-2">
                          Distributor

                          <Tooltip anchorSelect=".distributor" className="tooltip-list">
                            The company distributing our products to the customer
                          </Tooltip>
                        </th>
                        <th className="accountType text-capitalize font-weight-bolder opacity-7 ps-2">
                          Account Type

                          <Tooltip anchorSelect=".accountType" className="tooltip-list">
                            If this customer has multiple locations (even if only one has a Smoodi machine), it is 'Chain
                          </Tooltip>
                        </th>
                        <th className="secondContactName text-capitalize font-weight-bolder opacity-7 ps-2">
                          Second Contact Name

                          <Tooltip anchorSelect=".secondContactName" className="tooltip-list">
                            Enter the name of an additional contact person.
                          </Tooltip>
                        </th>
                        <th className="secondContactRole text-capitalize font-weight-bolder opacity-7 ps-2">
                          Second Contact Role

                          <Tooltip anchorSelect=".secondContactRole" className="tooltip-list">
                            Specify the job title or position of the secondary contact person
                          </Tooltip>
                        </th>
                        <th className="secondContactPhone text-capitalize font-weight-bolder opacity-7 ps-2">
                          Second Contact Phone Type

                          <Tooltip anchorSelect=".secondContactPhone" className="tooltip-list">
                            Specify if this phone number is for work or personal use.
                          </Tooltip>
                        </th>
                        <th className="createAt text-capitalize font-weight-bolder opacity-7 ps-2">
                          Created At

                          <Tooltip anchorSelect=".createAt" className="tooltip-list" >
                            Date when the customer's profile was created in the system, distinct from the machine installation date.
                          </Tooltip>
                        </th>
                        <th className="text-capitalize font-weight-bolder opacity-7 ps-2">
                          Is VIP
                        </th>
                        <th className="status text-capitalize font-weight-bolder opacity-7 ps-2">
                          Customer Status

                          <Tooltip anchorSelect=".status" className="tooltip-list">
                            If the machine is at a commercial location and operational, it's marked as 'Active'. In all other cases, it is considered 'Inactive'
                          </Tooltip>
                        </th>
                        <th className="action text-capitalize font-weight-bolder opacity-7 ps-2">
                          Action

                          <Tooltip anchorSelect=".action" className="tooltip-list">
                            Use this option to modify customer information or update their current status.
                          </Tooltip>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
            </div>
           
          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            id="context-menu"
          >
            <li>
              <button
                className="dropdown-item editBtn" id="edit_record_link"
              >
                <i className="fa fa-pencil fs-6"></i>{" "}
                <span className="ms-2">Edit</span>
              </button>
            </li>

              <li>
              {/* eslint-disable-next-line */}
                <a className="dropdown-item" id="view_record_link">
                  <i className="fa fa-eye f-6"></i>
                  <span className="ms-2"> View </span>
                </a>
              </li>
          </ul>
          
    
            {this.state.customerEditModal === true && (
            <EditCustomer 
              currentCustomerId={this.state.currentCustomerId} 
              customerEditModal={this.state.customerEditModal}
              handleCustomerClose={this.handleCustomerClose} 
              refreshTable={this.refreshTable}
            />)}
            {this.state.changeReactivateModal === true && (
              <ReactivateCustomerModal
                changeReactivateModal={this.state.changeReactivateModal}
                handleCloseReactivateCustomer={this.handleCloseReactivateCustomer}
                currentCustomerId={this.state.currentCustomerId}
                refreshTable={this.refreshTable}
              />
            )}
          </div>
        </div>
      </Suspense>
    );
  }
}

export default CustomersList;