import { ErrorMessage, FieldArray } from "formik";
import Datetime from "react-datetime";
import Select from "react-select";
import {
  WorkOrderInstallationSteps,
  WorkOrderTestPerformed,
  capitalizeWord,
  selectPriorityDB,
} from "../WorkOrderHelper";
import { selectMachineDB } from "../WorkOrderHelper";
import clsx from "clsx";

const Checklist = (props: {
  values: any;
  handleChange: any;
  handleBlur: any;
  setFieldValue: any;
  resetDueDate: any;
  resetPriority: any;
  resetNewTaskMachines:any;
  machinesDB: any;
  machineOneSelected:any;
  machineTwoSelected:any;
  setMachineOneSelected:any;
  setMachineTwoSelected:any;
  resetMachineOne : any;
  resetMachineTwo:any;
  setIsMachineDelployedModalOpen: any;  
  handleErrorCounter: (value: any) => void;
  errors: any;
  touched: any;
}) => {
  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    resetDueDate,
    resetPriority,
    resetNewTaskMachines,
    machinesDB,
    machineOneSelected,
    machineTwoSelected,
    setMachineOneSelected,
    setMachineTwoSelected,
    resetMachineOne,
    resetMachineTwo,
    setIsMachineDelployedModalOpen,
    handleErrorCounter,
    errors,
    touched
  } = props;

  return (
    <>
      <div className="wo-form-section">
        <h3>Installation Checklist</h3>
        <div
          style={{
            borderTop: "1px solid #dbdce4",
            marginBottom: "2rem",
          }}
        ></div>

        <div className="mt-15">
          <h5>Mandatory Steps</h5>
          <table className="table wo-table mt-4">
            <thead className="wo-thead">
              <tr>
                <th></th>
                {WorkOrderInstallationSteps.map((step) => (
                  <th key={step.id} className="wo-th">
                    {step.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="wo-tbody">
              {(
                Object.keys(
                  values.installationSteps
                ) as (keyof typeof values.installationSteps)[]
              ).map((key: any, index) => (
                <tr key={index}>
                  <td>{capitalizeWord(key)}</td>
                  {WorkOrderInstallationSteps.map((step, idx) => (
                    <td key={idx} data-label={step.name}>
                      <div className="center-radio">
                        <input
                          className="form-check-input"
                          type="radio"
                          id={`${key}_${step.id}`}
                          name={`installationSteps.${key}`}
                          value={step.id}
                          onChange={handleChange}
                          checked={values.installationSteps[key] === step.id}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-15">
          <h5>Test Performed</h5>
          <table className="table wo-table mt-4">
            <thead className="wo-thead">
              <tr>
                <th></th>
                {WorkOrderTestPerformed.map((test) => (
                  <th key={test.id} className="wo-th">
                    {test.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="wo-tbody">
              {(
                Object.keys(
                  values.testPerformance
                ) as (keyof typeof values.testPerformance)[]
              ).map((key: any, index) => (
                <tr key={index}>
                  <td>
                    {capitalizeWord(key) === "Blended4Smoodies"
                      ? "Blended 4 Smoodies"
                      : capitalizeWord(key)}
                  </td>
                  {WorkOrderTestPerformed.map((test, idx) => (
                    <td key={idx} data-label={test.name}>
                      <div className="center-radio">
                        <input
                          className="form-check-input"
                          type="radio"
                          id={`${key}_${test}`}
                          name={`testPerformance.${key}`}
                          value={test.id}
                          onChange={handleChange}
                          checked={values.testPerformance[key] === test.id}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-15">
          <h5>Trained staff on the following</h5>
          <table className="table wo-table mt-4">
            <thead className="wo-thead">
              <tr>
                <th></th>
                {WorkOrderInstallationSteps.map((step) => (
                  <th key={step.id} className="wo-th">
                    {step.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="wo-tbody">
              {(
                Object.keys(
                  values.staffTraining
                ) as (keyof typeof values.staffTraining)[]
              ).map((key: any, index) => (
                <tr key={index}>
                  <td>{capitalizeWord(key)}</td>
                  {WorkOrderInstallationSteps.map((step, idx) => (
                    <td key={idx} data-label={step.name}>
                      <div className="center-radio">
                        <input
                          className="form-check-input"
                          type="radio"
                          id={`${key}_${step}`}
                          name={`staffTraining.${key}`}
                          value={step.id}
                          onChange={handleChange}
                          checked={values.staffTraining[key] === step.id}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-15">
          <h5>Optional Materials Installed</h5>
          <table className="table wo-table mt-4">
            <thead className="wo-thead">
              <tr>
                <th></th>
                {WorkOrderInstallationSteps.map((step) => (
                  <th key={step.id} className="wo-th">
                    {step.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="wo-tbody">
              {(
                Object.keys(
                  values.materialInstallation
                ) as (keyof typeof values.materialInstallation)[]
              ).map((key: any, index) => (
                <tr key={index}>
                  <td>{capitalizeWord(key)}</td>
                  {WorkOrderInstallationSteps.map((step, idx) => (
                    <td key={idx} data-label={step.name}>
                      <div className="center-radio">
                        <input
                          className="form-check-input"
                          type="radio"
                          id={`${key}_${step}`}
                          name={`materialInstallation.${key}`}
                          value={step.id}
                          onChange={handleChange}
                          checked={values.materialInstallation[key] === step.id}
                        />
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="wo-form-group mt-5">
        <h3>Did you install a second machine?</h3>
        <div className="form-group">
          {Array.from(selectMachineDB).map((value: any, id) => (
            <div
              className="form-check form-check-sm form-check-custom form-check-solid me-5 align-items-center d-flex mt-1 ms-2"
              key={id}
            >
              <label className="form-check-label fs-5 mb-0 ms-2" key={id}>
                <input
                  id="isSecondMachineInstalled"
                  className="form-check-input"
                  type="radio"
                  name="isSecondMachineInstalled"
                  value={value.id}
                  checked={values.isSecondMachineInstalled === value.id}
                  onChange={() =>{
                    setFieldValue("isSecondMachineInstalled", value.id)
                    values.isSecondMachineInstalled = value.id;
                    handleErrorCounter(values)
                  }}
                />
                <span className="form-check-label fs-5 mb-0 ms-2">
                  {value.name}
                </span>
              </label>
            </div>
          ))}
        </div>
      </div>

    <div
      className={clsx("wo-form-group", {
        "error-box":
          Boolean(errors.machineId1) &&
          touched.machineId1,
      })}
    >
      <div className="row mt-15">
        <div className="col-12 wo-form-group">
          <h3 className="required">Machine 1</h3>
            <Select
              ref= {resetMachineOne}
              className={clsx("react-select", {
                "is-invalid":
                  Boolean(errors.machineId1) &&
                  touched.machineId1,
              })}
              classNamePrefix="my-react-select"
              placeholder=""
              name="machineId1"
              id="machineId1"
              defaultValue={machineOneSelected?.value}
              onChange={(selected: any) => {
                if(selected.isDeployed) setIsMachineDelployedModalOpen(true)
                resetNewTaskMachines?.current?.setValue("");
                setMachineOneSelected(selected);
                setFieldValue("machineId1",selected.value);
                values.machineId1 = selected.value;
                handleErrorCounter(values)
              }}
              options={Array.from(machinesDB).map(
                (value: any) => {
                  return {
                    value: value.id,
                    label: `${value.name ?? ""} (${value?.serialNumber ?? ""
                      })`,
                    isDeployed: value?.machineStatus?.name === "Deployed"
                  };
                }
              )}
            />
        </div>
        <div
          className={clsx("", {
            "wo-error mt-4":
              Boolean(errors.machineId1) &&
              touched.machineId1,
          })}
            >
              <i
                className="fa fa-exclamation-circle me-1 ms-2 error-icon"
                aria-hidden="true"
              ></i>
              <ErrorMessage name="machineId1" />
            </div>
      </div>
      </div>

      {values.isSecondMachineInstalled === true && (
          <div
          className={clsx("wo-form-group", {
            "error-box":
              Boolean(errors.machineId2) &&
              touched.machineId2,
          })}
        >
        <div className="row">
          <div className="col-12 wo-form-group">
            <h3 className="required">Machine 2</h3>
            <Select
              ref={resetMachineTwo}
              className={clsx("react-select", {
                "is-invalid":
                  Boolean(errors.machineId12) &&
                  touched.machineId2,
              })}
              classNamePrefix="my-react-select"
              placeholder=""
              name="machineId2"
              id="machineId2"
              defaultValue={machineTwoSelected?.value}
              onChange={(selected: any) => {
                if(selected.isDeployed) setIsMachineDelployedModalOpen(true)
                resetNewTaskMachines?.current?.setValue("");
                setMachineTwoSelected(selected);
                setFieldValue("machineId2",selected.value);
                values.machineId2 = selected.value;
                handleErrorCounter(values)
              }}
              options={Array.from(machinesDB).map(
                (value: any) => {
                  return {
                    value: value.id,
                    label: `${value.location?.name ?? ""} (${value?.serialNumber ?? ""
                      })`,
                    isDeployed: value?.machineStatus?.name === "Deployed"
                  };
                }
              )}
            />
          </div>
          <div
          className={clsx("", {
            "wo-error mt-4":
              Boolean(errors.machineId2) &&
              touched.machineId2,
          })}
            >
              <i
                className="fa fa-exclamation-circle me-1 ms-2 error-icon"
                aria-hidden="true"
              ></i>
              <ErrorMessage name="machineId2" />
            </div>
        </div>
        </div>
      )}
      {(machineOneSelected || machineTwoSelected)  && (
         <div className="wo-form-section mt-15">
         <h3>Tasks Still Need to Be Performed</h3>
         <FieldArray name="requiredTask">
           {({push, remove}) => (
             <>
               {values.requiredTask.map((task: any, index: any) => (
                 <div key={index} className="form-field">
                   <div className="row mt-4">
                     {index !== 0 && (
                       <div>
                         <i
                           className="fa fa-times float-end me-4 mt-2"
                           onClick={() => remove(index)}
                           aria-hidden="true"
                           style={{cursor: "pointer"}}
                         ></i>
                       </div>
                     )}
                      <div className="col-3">
                       <label
                         htmlFor={`requiredTask[${index}].action`}
                         className="wo-label"
                       >
                         Machine
                       </label>
                      
                        <Select
                          ref={resetNewTaskMachines}
                          className="react-select"
                          classNamePrefix="my-react-select"
                          placeholder=""
                          name={`requiredTask[${index}].newTaskMachineId`}
                          id={`requiredTask[${index}].newTaskMachineId`}
                          onChange={(option: any) => {
                            setFieldValue(
                              `requiredTask[${index}].newTaskMachineId`,
                              option.value
                            );
                          }}
                          options={Array.from([machineOneSelected,machineTwoSelected])?.map(
                            (x: any) => {
                              return {
                                value: x?.value,
                                label: x?.label,
                                isDeployed: x?.isDeployed
                              };
                            }
                          )}
                        />
                     </div>
                     <div className="col-3">
                       <label
                         htmlFor={`requiredTask[${index}].action`}
                         className="wo-label"
                       >
                         Action
                       </label>
                       <input
                         id={`requiredTask[${index}].action`}
                         type="text"
                         name={`requiredTask[${index}].action`}
                         onChange={handleChange}
                         onBlur={handleBlur}
                         value={task.action}
                         className={`wo-input form-control`}
                       />
                     </div>
                     <div className="col-3">
                       <label
                         htmlFor={`requiredTask[${index}].priority`}
                         className="wo-label"
                       >
                         Priority
                       </label>
                       <Select
                         ref={resetPriority}
                         className="react-select"
                         placeholder=""
                         classNamePrefix="my-react-select"
                         id={`requiredTask[${index}].priority`}
                         name={`requiredTask[${index}].priority`}
                         options={selectPriorityDB.map((value: any) => ({
                           value: value.id,
                           label: value.name,
                         }))}
                         onChange={(option: any) => {
                           setFieldValue(
                             `requiredTask[${index}].priority`,
                             option.value
                           );
                         }}
                       />
                     </div>
                     <div className="col-3">
                       <label
                         htmlFor={`requiredTask[${index}].dueDate`}
                         className="wo-label"
                       >
                         Due Date
                       </label>
                       <Datetime
                         ref={resetDueDate}
                         className="form-input"
                         dateFormat="YYYY-MM-DD"
                         timeFormat="HH:mm:ss"
                         closeOnSelect
                         value={task.dueDate}
                         onChange={(e: any) => {
                           const dt = e["_d"];
                           setFieldValue(`requiredTask[${index}].dueDate`, dt);
                         }}
                       />
                     </div>
                   </div>
                 </div>
               ))}
               <div className="col-xl-6 col-lg-6 col-md-6 col-12 mt-8 mb-4">
                 <button
                   type="button"
                   className="btn btn-secondary me-4 fs-4 mb-4"
                   style={{
                     background: "#afbace",
                     color: "white",
                     padding: "5px 40px",
                   }}
                   onClick={() =>
                     push({
                       action: "",
                       priority: "",
                       dueDate: null,
                     })
                   }
                 >
                   +
                 </button>
               </div>
             </>
           )}
         </FieldArray>
       </div>
      )}
    </>
  );
};

export default Checklist;
