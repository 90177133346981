const Columns = ({ 
    columnData, 
    selectedColumns, 
    handleColumnDataSelect, 
    handleColumnDataSubmit,
    handleSelectAll,
  }: any) => {
    const allSelected = columnData.every((col: any) => selectedColumns.includes(col.key));
  return (
    <>
     <div>
        <div
          className="modal fade"
          id="columnsModal"
          tabIndex={-1}
          aria-labelledby="columnsModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h3
                  className="modal-title mb-4 text-gray-600"
                  id="columnsModalLabel"
                >
                  Show Columns
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="container">
                  <div className="row mb-3">
                    <div className="col-6">
                      <label className="form-check form-check-custom form-check-sm align-items-center mt-1 fw-semibold me-3">
                        <input
                          type="checkbox"
                          className="form-check-input cursor-pointer me-2"
                          checked={allSelected}
                          onChange={handleSelectAll}
                        />
                        {allSelected ? "Deselect All" : "Select All"}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    {columnData.map((value: any) => (
                      <div className="col-6" key={value.id}>
                        <label className="form-check form-check-custom form-check-sm align-items-center mt-1 fw-semibold">
                          <input
                            type="checkbox"
                            className="toggle-vis form-check-input cursor-pointer me-2"
                            data-column={value.id}
                            data-key={value.key}
                            defaultChecked
                            checked={selectedColumns.includes(value.key)}
                            value={value.key}
                            onClick={handleColumnDataSelect}
                          />
                          {value.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  onClick={handleColumnDataSubmit}
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  };
  export default Columns;