import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

interface CreateBlenderInput {
  id?: string;
  title?: string;
  description?: string;
  versionNo?: string;
  isActive?: boolean;
}
export async function createBlenders(input: CreateBlenderInput) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
          mutation createBlender (
            $input: CreateBlenderInput!
          ){
            createBlender(
              createBlenderInput: $input
              )  {
                id
                versionNo
                title
                description
                launchDateAndTime
                createdAt
                updatedAt
                createdById
                updatedById
                isActive
              }
          }
          `,
      variables: {
        input,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}

export async function getBlenders(
  where: {} = {},
  pagination: {} | undefined = undefined
) {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
                query getBlenders(
                  $where: BlendersResolver_GetBlenders_FilterInputType,
                  $paginate: PaginatorArgs
                  ) {
                    getBlenders (where:$where, paginate: $paginate) {
                      data {
                        id
                        versionNo
                        title
                        mediaUrl
                        description
                        launchDateAndTime
                        createdAt
                        updatedAt
                        createdById
                        updatedById
                        isActive
                      }
                      totalPages
                      total
                      perPage
                      page
                    
                  }
                }
                `,
      variables: { where, paginate: pagination },
    },
  });
  return data?.data?.data?.getBlenders;
}

export async function getBlendersList() {
  const data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            query {
              getBlendersList {
                id
                versionNo
                title
                mediaUrl
                description
                launchDateAndTime
                createdAt
                updatedAt
                createdById
                updatedById
                isActive
              }
            }
            `,
      variables: {},
    },
  });
  return data?.data?.data?.getBlendersList;
}

export async function updateBlenders(input: CreateBlenderInput, id?: string) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
            mutation updateBlender (
              $id: String!
              $input: CreateBlenderInput!
            ){
              updateBlender(
                createBlenderInput: $input
                id: $id
                )  {
                  id
                  versionNo
                  description
                  title
                  launchDateAndTime
                  createdAt
                  updatedAt
                  isActive
                }
            }
            `,
      variables: {
        input,
        id,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}



export async function updateBlenderStatus(id: string, isActive: boolean) {
  let data;
  data = await axios({
    url: API_URL,
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      query: `
       mutation ($id: String!, $isActive: Boolean! ) {
        updateBlenderStatus(
          updateBlenderStatusInput: { id: $id, isActive: $isActive }
              )
            }
            `,
      variables: {
        id,
        isActive,
      },
    },
  }).catch((e: any) => (data = e.response));
  return data;
}
