import React, { FC, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-responsive-modal";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from 'babel-plugin-relay/macro';
 import { ViewStatusModel_getMachineDeviceStatusLogQuery } from "./__generated__/ViewStatusModel_getMachineDeviceStatusLogQuery.graphql"

const getMachineDeviceStatusLog = graphql`
query ViewStatusModel_getMachineDeviceStatusLogQuery($machineId:String!){
  getMachineDeviceStatusLog(machineId:$machineId){
    id
    reportDateAndTime
    machineId
    machine {
      id
      uuid
      serialNumber
      macAddress
      name
      deploymentDate
      locationId
      deployingPersonId
      softwareVersion
      hardwareVersion
      connectionStatus
      createdById
      createdAt
      updatedById
      updatedAt
      isActive
      remark
      lastHearbeatSyncAt
      lastSanitizeTime
      retiredAt
      retiredById
      isRetired
      blenderId
      boardId
      noteRelatedToMechanicalHardwareVersion
      isPilot
      trialEndDate
      location {
        name
      }
    }
    createdAt
    blenderActBottomPos
    blenderActMaxCur
    blenderActMaxCurTravel
    blenderActTopPos
    blenderMotorCur
    cleanValveCur
    cupSensorBlkdVal
    cupSensorCupDiffVal
    cupSensorUnBlkVal
    doorActBottomPos
    doorActMaxCur
    doorActTopPos
    fillValveCur
    misc12v
    misc5v
    miscTemp
    peelSealBlenderLower
    peelSealOpenDoor
    pumpCur
    blenderActBottomPosResult
    blenderActMaxCurResult
    blenderActMaxCurTravelResult
    blenderActTopPosResult
    blenderMotorCurResult
    cleanValveCurResult
    cupSensorBlkdValResult
    cupSensorCupDiffValResult
    cupSensorUnBlkValResult
    doorActBottomPosResult
    doorActMaxCurResult
    doorActTopPosResult
    fillValveCurResult
    misc12vResult
    misc5vResult
    miscTempResult
    peelSealBlenderLowerResult
    peelSealOpenDoorResult
    pumpCurResult
    blenderMotorCurRatio
    blenderMotorCurRatioResult
    waterFlowRate
    cleanValveWaterFlowRate
  }
}`
type Props = {
  currentMachineId: string;
  viewStatusModelOpen: boolean;
  handleCloseViewStatus: () => void;
  refreshTable?: any;
};

const ViewStatusModel: FC<Props> = ({
  viewStatusModelOpen,
  handleCloseViewStatus,
  currentMachineId
}) => {
  const navigate = useNavigate();
  if (!currentMachineId) {
    navigate("/machines/list");
  }
  React.useState<any>(null);

  const machineStatusRecord = useLazyLoadQuery<ViewStatusModel_getMachineDeviceStatusLogQuery>(
    getMachineDeviceStatusLog,
    {
      machineId: currentMachineId,
    },
    {
      fetchPolicy: "network-only",
    });

  const machineStatus: any = machineStatusRecord?.getMachineDeviceStatusLog ?? []

  return (
    <div>
      <Suspense>
        <Modal
          open={viewStatusModelOpen}
          onClose={() => handleCloseViewStatus()}
          role="modal"
          center
          classNames={{
            root: "content-wrapper",
          }}
        >
          <form
            id="kt_modal_add_lesson_form"
            className="form-Status"
            autoComplete="off"
            noValidate
          >
            <div className="modal-header p-2 pb-4 mb-4">
              <h4 className="modal-title fs-1">View Status Log</h4>
            </div>
            {machineStatus?.map((value: any) => {
              return (
                <>
                  <div className="card">
                    <div className="row text-success bg-light-success mb-6 rounded-3">
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-0">
                        <label className="fs-4 m-4 ">
                          Machine Id : <span className="fw-bold">{value?.machine?.uuid ?? ""}</span>
                        </label>
                      </div>

                      <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-0 ">
                        <label className="fs-4 m-4">
                          Location Name : <span className="fw-bold">{value?.machine?.location?.name ?? ""}</span>
                        </label>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-0  ">
                        <label className="fs-4 m-4">
                          Serial Number : <span className="fw-bold">{value?.machine?.serialNumber ?? ""}</span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="modal-body">
                    <div>
                      <div className="row fs-4 text-success mb-1">
                        Blender Actuator Test
                      </div>

                      <div className="row bg-light-success rounded-3 text-success p-0 mb-1">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-0">
                          <label className="fs-4 mt-2 mb-2 ">
                            Bottom Position : <span className="fw-bold">{value?.blenderActBottomPos}</span>
                          </label>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-0  ">
                          <label className="fs-4 mt-2 mb-2 ">
                            Top Position : <span className="fw-bold">{value?.blenderActTopPos}</span>
                          </label>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-0 ">
                          <label className="fs-4 mt-2 mb-2">
                            Max Current : <span className="fw-bold">{value?.blenderActMaxCur}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row fs-4 text-success w-100 mb-1">
                        Door Actuator Test
                      </div>

                      <div className="row bg-light-success rounded-3  text-success mb-1">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-0">
                          <label className="fs-4 mt-2 mb-2">
                            Bottom Position : <span className="fw-bold">{value.doorActBottomPos}</span>
                          </label>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12  p-0">
                          <label className="fs-4 mt-2 mb-2">
                            Top Position : <span className="fw-bold">{value.doorActTopPos}</span>

                          </label>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-0">
                          <label className="fs-4 mt-2 mb-2">
                            Max Current : <span className="fw-bold">{value.doorActMaxCur}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* <div>
                    <div className="row fs-4 text-success w-100 mb-1 ">
                      Limit Switch Test
                    </div>

                    <div className="row bg-light-success rounded-3  text-success mb-1">
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-0 ">
                        <label className="fs-4 mt-2 mb-2">
                          Tube Door Close Switch :
                          <span className="fw-bold">{value}</span>
                        </label>
                      </div>
                      <div className="col-xl-5 col-lg-6 col-md-6 col-12 p-0">
                        <label className="fs-4 mt-2 mb-2">
                          Tube Door Installed Switch :
                          <span className="fw-bold">{value}</span>
                        </label>
                      </div>
                      <div className="col-xl-3 col-lg-6 col-md-6 col-12  p-0">
                        <label className="fs-4 mt-2 mb-2">
                          Collision Switch :
                           <span className="fw-bold">{value}</span>
                        </label>
                      </div>
                    </div>
                  </div> */}
                    <div>
                      <div className="row fs-4 text-success mb-1">Miscellaneous</div>

                      <div className="row bg-light-success rounded-3 text-success p-0 mb-1">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-0">
                          <label className="fs-4 mt-2 mb-2">
                            Temperature : <span className="fw-bold">{value.miscTemp}</span>
                          </label>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-0">
                          <label className="fs-4 mt-2 mb-2">
                            12V DC Current : <span className="fw-bold">{value.misc12v}</span>
                          </label>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-0">
                          <label className="fs-4 mt-2 mb-2">
                            5V DC Current : <span className="fw-bold">{value.misc5v}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12 ">
                        <label className="row fs-4 text-success mb-1">
                          Clean Valve Test
                        </label>
                        <div className="row bg-light-success rounded-3 text-success p-0 mb-1">
                          <label className="fs-4 mt-2 mb-2 p-0">
                            Clean Valve Current : <span className="fw-bold">{value.cleanValveCur}</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12 ps-xl-5">
                        <label className="row fs-4 text-success mb-1">
                          Fill Valve Test
                        </label>
                        <div className="row bg-light-success rounded-3 text-success p-0 mb-1">
                          <label className="fs-4 mt-2 mb-2 p-0">
                            Fill Valve Current : <span className="fw-bold">{value.fillValveCur}</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-6 col-md-6 col-12 ps-xl-5  ">
                        <label className="row fs-4 text-success mb-1">
                          Pump Test
                        </label>
                        <div className="row bg-light-success rounded-3 text-success p-0 mb-1">
                          <label className="fs-4 mt-2 mb-2 p-0">
                            Pump Current : <span className="fw-bold">{value.pumpCur}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row fs-4 text-success mb-1">
                        Blender Motor Test
                      </div>

                      <div className="row bg-light-success rounded-3 text-success p-0 mb-1">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12 p-0">
                          <label className="fs-4 mt-2 mb-2">
                            Blender Motor Current : <span className="fw-bold">{value.blenderMotorCur}</span>
                          </label>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12 p-0">
                          <label className="fs-4 mt-2 mb-2">
                            Blender Max/Min Current Ratio : <span className="fw-bold">{value.blenderMotorCurRatio}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row fs-4 text-success mb-1">
                        Cup Sensor Test
                      </div>

                      <div className="row bg-light-success rounded-3 text-success p-0 mb-1">
                        <div className="col-xl-3 col-lg-6 col-md-6 col-12 p-0">
                          <label className="fs-4 mt-2 mb-2">
                            Blocked Value : <span className="fw-bold">{value.cupSensorBlkdVal}</span>
                          </label>
                        </div>
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-0">
                          <label className="fs-4 mt-2 mb-2">
                            UnBlocked Value : <span className="fw-bold">{value.cupSensorUnBlkVal}</span>
                          </label>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-md-6 col-12 p-0">
                          <label className="fs-4 mt-2 mb-2 ">
                            Cup Difference Value : <span className="fw-bold">{value.cupSensorCupDiffVal}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row fs-4 text-success mb-1">
                        Distance Sensor Test
                      </div>

                      <div className="row bg-light-success rounded-3 text-success p-0 mb-1">
                        <div className="col-xl-4 col-lg-6 col-md-6 col-12 p-0">
                          <label className="fs-4 mt-2 mb-2">
                            Open Door Value : <span className="fw-bold">{value.peelSealOpenDoor}</span>
                          </label>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-12 p-0 ">
                          <label className="fs-4 mt-2 mb-2 ">
                            Blender Lower Value : <span className="fw-bold">{value.peelSealBlenderLower}</span>
                          </label>
                        </div>
                        <div className="col-xl-2 col-lg-6 col-md-6 col-12 p-0 ">
                          <label className="fs-4 mt-2 mb-2">
                            Result :
                            {/* <span className="fw-bold">{value}</span> */}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
          </form>
        </Modal>
      </Suspense>
    </div>
  );
};

export default ViewStatusModel;
